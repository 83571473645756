<template>
    <div>
        <el-form-item label="数据类型">
            <el-select size="mini" v-model="item.EmptyAnimation.dataType" placeholder="请选择数据类型" @change="changeAnimationDataType">
                <el-option
                    v-for="item in dataType"
                    :key="item"
                    :label="item"
                    :value="item"></el-option>
            </el-select>
        </el-form-item>
        <!-- Bool类型 -->
        <div v-show="item.EmptyAnimation.dataType=='Bool'">
            <el-form-item label="运算符">
                <el-radio-group v-model="item.EmptyAnimation.boolData.formulaType" @change="changePipeline">
                    <el-radio :label="'empty'">无</el-radio>
                    <el-radio :label="'&'">&</el-radio>
                    <el-radio :label="'||'">||</el-radio>
                    <el-radio :label="'!'">!</el-radio>
                </el-radio-group>
            </el-form-item>
            <!-- 运算符为 '无'和'!' -->
            <div class="con-middle-tag-head" style="margin: 10px 0;" v-show="item.EmptyAnimation.boolData.formulaType=='empty'||item.EmptyAnimation.boolData.formulaType=='!'">
                <div>
                    <el-button v-if="!item.EmptyAnimation.boolData.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(item.EmptyAnimation)">选择Tag</el-button>
                    <span v-else class="close-lube-btn">
                        {{item.EmptyAnimation.boolData.tag}}
                        <span class="el-icon-error close-lube" @click="clearItemTag(item)"></span>
                    </span>
                </div>
                <div>
                    <el-input size="mini" v-model="item.EmptyAnimation.boolData.tagValue" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                </div>
            </div>
            <!-- 运算符为 '&' -->
            <div v-show="item.EmptyAnimation.boolData.formulaType=='&'">
                <div class="flex-change">
                    <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                    <div v-if="item.EmptyAnimation.boolData.boolTagData">
                        <el-button type="text" @click="btAddTag(item.EmptyAnimation.boolData.boolTagData)">新增</el-button>
                        <el-button :style="item.EmptyAnimation.boolData.boolTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.EmptyAnimation.boolData.boolTagData.length==2" @click="btDelTag(item.EmptyAnimation.boolData.boolTagData)">删除</el-button>
                    </div>
                </div>
                <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                    <div style="width:50%;margin-bottom:10px;" v-for="(btItem, btIndex) in item.EmptyAnimation.boolData.boolTagData" :key="btIndex">
                        <div style="display:flex;">
                            <el-button v-if="!btItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(btItem)">选择Tag</el-button>
                            <span v-else class="close-lube-btn">
                                {{btItem.tag}}
                                <span class="el-icon-error close-lube" @click="clearBtItemTag(btItem)"></span>
                            </span>
                            <div v-show="item.EmptyAnimation.boolData.boolTagData.length-1!=btIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">&</div>
                        </div>
                                
                        <!-- 运算符为 '&' 的侧滑选择Tag -->
                        <el-drawer
                            title="选择Tag"
                            :with-header="false"
                            :visible.sync="btItem.tagDrawer"
                            direction="rtl"
                            size="800px">
                            <div class="drawer-title">选择Tag</div>
                            <div class="drawer-content table-tree">
                            <el-table :data="tagTableData" height="700">
                                <el-table-column label="勾选" width="70">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="btItem.tagRadio" @change="checkTagChange(scope.row)">
                                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                                    </el-radio-group>
                                </template>
                                </el-table-column>
                                <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                <el-table-column prop="pointName" label="点名"></el-table-column>
                                <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                <el-table-column prop="cycle" label="采集周期"></el-table-column>
                            </el-table>
                            </div>
                            <div class="drawer-bottom">
                            <el-button size="small" type="primary" @click="btItem.tagDrawer=false">取消</el-button>
                            <el-button
                                size="small"
                                type="primary"
                                @click="tagBtItemDrawerOK(btItem)"
                                class="drawer-bottom-ok">确 定</el-button>
                            </div>
                        </el-drawer>
                    </div>
                </div>
                <div>
                    <el-input size="mini" v-model="item.EmptyAnimation.boolData.tagValue" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                </div>
            </div>
            <!-- 运算符为 '||' -->
            <div v-show="item.EmptyAnimation.boolData.formulaType=='||'">
                <div style="display:flex;justify-content:space-between;align-items:center;">
                    <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                    <div v-if="item.EmptyAnimation.boolData.boolTagData">
                        <el-button type="text" @click="btAddTag(item.EmptyAnimation.boolData.boolTagData)">新增</el-button>
                        <el-button :style="item.EmptyAnimation.boolData.boolTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.EmptyAnimation.boolData.boolTagData.length==2" @click="btDelTag(item.EmptyAnimation.boolData.boolTagData)">删除</el-button>
                    </div>
                </div>
                <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                    <div style="width:50%;margin-bottom:10px;" v-for="(btItem, btIndex) in item.EmptyAnimation.boolData.boolTagData" :key="btIndex">
                        <div style="display:flex;">
                            <el-button v-if="!btItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(btItem)">选择Tag</el-button>
                            <span v-else class="close-lube-btn">
                                {{btItem.tag}}
                                <span class="el-icon-error close-lube" @click="clearBtItemTag(btItem)"></span>
                            </span>
                            <div v-show="item.EmptyAnimation.boolData.boolTagData.length-1!=btIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">||</div>
                        </div>
                        
                        <!-- 运算符为 '||' 的侧滑选择Tag -->
                        <el-drawer
                            title="选择Tag"
                            :with-header="false"
                            :visible.sync="btItem.tagDrawer"
                            direction="rtl"
                            size="800px">
                            <div class="drawer-title">选择Tag</div>
                            <div class="drawer-content table-tree">
                            <el-table :data="tagTableData" height="700">
                                <el-table-column label="勾选" width="70">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="btItem.tagRadio" @change="checkTagChange(scope.row)">
                                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                                    </el-radio-group>
                                </template>
                                </el-table-column>
                                <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                <el-table-column prop="pointName" label="点名"></el-table-column>
                                <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                <el-table-column prop="cycle" label="采集周期"></el-table-column>
                            </el-table>
                            </div>
                            <div class="drawer-bottom">
                            <el-button size="small" type="primary" @click="btItem.tagDrawer=false">取消</el-button>
                            <el-button
                                size="small"
                                type="primary"
                                @click="tagBtItemDrawerOK(btItem)"
                                class="drawer-bottom-ok">确 定</el-button>
                            </div>
                        </el-drawer>
                    </div>
                </div>
                <div>
                    <el-input size="mini" v-model="item.EmptyAnimation.boolData.tagValue" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                </div>
            </div>
        </div>
        <!-- Real类型 -->
        <div v-show="item.EmptyAnimation.dataType=='Real'">
            <el-form-item label="运算符">
                <el-radio-group v-model="item.EmptyAnimation.realData.formulaType" @change="changePipeline">
                    <el-radio :label="'empty'">无</el-radio>
                    <el-radio :label="'+'">+</el-radio>
                    <el-radio :label="'-'">-</el-radio>
                </el-radio-group>
            </el-form-item>
            <!-- 运算符为 '无' -->
            <div class="con-middle-tag-head" style="margin-bottom:10px;" v-show="item.EmptyAnimation.realData.formulaType=='empty'">
                <div>
                    <el-button v-if="!item.EmptyAnimation.realData.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(item.EmptyAnimation)">选择Tag</el-button>
                    <span v-else class="close-lube-btn">
                        {{item.EmptyAnimation.realData.tag}}
                        <span class="el-icon-error close-lube" @click="clearItemTag(item)"></span>
                    </span>
                </div>
                <div>
                    <el-input size="mini" v-model="item.EmptyAnimation.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                </div>
            </div>
            <!-- 运算符为 '+' -->
            <div v-show="item.EmptyAnimation.realData.formulaType=='+'">
                <div style="display:flex;justify-content:space-between;align-items:center;">
                    <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                    <div v-if="item.EmptyAnimation.realData.realTagData">
                        <el-button type="text" @click="rtAddTag(item.EmptyAnimation.realData.realTagData)">新增</el-button>
                        <el-button :style="item.EmptyAnimation.realData.realTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.EmptyAnimation.realData.realTagData.length==2" @click="rtDelTag(item.EmptyAnimation.realData.realTagData)">删除</el-button>
                    </div>
                </div>
                <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                    <div style="width:50%;margin-bottom:10px;" v-for="(rtItem, rtIndex) in item.EmptyAnimation.realData.realTagData" :key="rtIndex">
                        <div style="display:flex;">
                            <el-button v-if="!rtItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(rtItem)">选择Tag</el-button>
                            <span v-else class="close-lube-btn">
                                {{rtItem.tag}}
                                <span class="el-icon-error close-lube" @click="clearRtItemTag(rtItem)"></span>
                            </span>
                            <div v-show="item.EmptyAnimation.realData.realTagData.length-1!=rtIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">+</div>
                        </div>
                        
                        <!-- 运算符为 '+' 的侧滑选择Tag -->
                        <el-drawer
                            title="选择Tag"
                            :with-header="false"
                            :visible.sync="rtItem.tagDrawer"
                            direction="rtl"
                            size="800px">
                            <div class="drawer-title">选择Tag</div>
                            <div class="drawer-content table-tree">
                            <el-table :data="tagTableData" height="700">
                                <el-table-column label="勾选" width="70">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="rtItem.tagRadio" @change="checkTagChange(scope.row)">
                                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                                    </el-radio-group>
                                </template>
                                </el-table-column>
                                <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                <el-table-column prop="pointName" label="点名"></el-table-column>
                                <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                <el-table-column prop="cycle" label="采集周期"></el-table-column>
                            </el-table>
                            </div>
                            <div class="drawer-bottom">
                            <el-button size="small" type="primary" @click="rtItem.tagDrawer=false">取消</el-button>
                            <el-button
                                size="small"
                                type="primary"
                                @click="tagRtItemDrawerOK(rtItem)"
                                class="drawer-bottom-ok">确 定</el-button>
                            </div>
                        </el-drawer>
                    </div>
                </div>
                <div>
                <el-input size="mini" v-model="item.EmptyAnimation.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                </div>
            </div>
            <!-- 运算符为 '-' -->
            <div v-show="item.EmptyAnimation.realData.formulaType=='-'">
                <div style="display:flex;justify-content:space-between;align-items:center;">
                    <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                    <div v-if="item.EmptyAnimation.realData.realTagData">
                        <el-button type="text" @click="rtAddTag(item.EmptyAnimation.realData.realTagData)">新增</el-button>
                        <el-button :style="item.EmptyAnimation.realData.realTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.EmptyAnimation.realData.realTagData.length==2" @click="rtDelTag(item.EmptyAnimation.realData.realTagData)">删除</el-button>
                    </div>
                </div>
                <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                    <div style="width:50%;margin-bottom:10px;" v-for="(rtItem, rtIndex) in item.EmptyAnimation.realData.realTagData" :key="rtIndex">
                        <div style="display:flex;">
                            <el-button v-if="!rtItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(rtItem)">选择Tag</el-button>
                            <span v-else class="close-lube-btn">
                                {{rtItem.tag}}
                                <span class="el-icon-error close-lube" @click="clearRtItemTag(rtItem)"></span>
                            </span>
                            <div v-show="item.EmptyAnimation.realData.realTagData.length-1!=rtIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">-</div>
                        </div>
                        
                        <!-- 运算符为 '-' 的侧滑选择Tag -->
                        <el-drawer
                            title="选择Tag"
                            :with-header="false"
                            :visible.sync="rtItem.tagDrawer"
                            direction="rtl"
                            size="800px">
                            <div class="drawer-title">选择Tag</div>
                            <div class="drawer-content table-tree">
                            <el-table :data="tagTableData" height="700">
                                <el-table-column label="勾选" width="70">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="rtItem.tagRadio" @change="checkTagChange(scope.row)">
                                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                                    </el-radio-group>
                                </template>
                                </el-table-column>
                                <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                <el-table-column prop="pointName" label="点名"></el-table-column>
                                <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                <el-table-column prop="cycle" label="采集周期"></el-table-column>
                            </el-table>
                            </div>
                            <div class="drawer-bottom">
                            <el-button size="small" type="primary" @click="rtItem.tagDrawer=false">取消</el-button>
                            <el-button
                                size="small"
                                type="primary"
                                @click="tagRtItemDrawerOK(rtItem)"
                                class="drawer-bottom-ok">确 定</el-button>
                            </div>
                        </el-drawer>
                    </div>
                </div>
                <div>
                    <el-input size="mini" v-model="item.EmptyAnimation.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                </div>
            </div>
        </div>
        <!-- 侧滑选择Tag -->
        <el-drawer
            title="选择Tag"
            :with-header="false"
            :visible.sync="item.EmptyAnimation.tagDrawer"
            direction="rtl"
            size="800px">
            <div class="drawer-title">选择Tag</div>
            <div class="drawer-content table-tree">
            <el-table :data="tagTableData" height="700">
                <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                    <el-radio-group v-model="item.EmptyAnimation.tagRadio" @change="checkTagChange(scope.row)">
                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                    </el-radio-group>
                </template>
                </el-table-column>
                <el-table-column prop="number" label="序号" width="50"></el-table-column>
                <el-table-column prop="dataType" label="数据类型"></el-table-column>
                <el-table-column prop="pointName" label="点名"></el-table-column>
                <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                <el-table-column prop="cycle" label="采集周期"></el-table-column>
            </el-table>
            </div>
            <div class="drawer-bottom">
            <el-button size="small" type="primary" @click="item.EmptyAnimation.tagDrawer=false">取消</el-button>
            <el-button
                size="small"
                type="primary"
                @click="tagParameterOK(item)"
                class="drawer-bottom-ok">确 定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dataType: ['Bool','Real'],//文本 -> 数据类型
            tagSelectObj: {},
        }
    },
    props: {
        selectCell:{
            type: String | Object
        },
        drawerNode: {
            type: Object
        },
        tagTableData: {
            type: Array
        },
        item: {
            type: Object
        }
    },
    methods: {
        changeAnimationDataType(val) {
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
        },
        changePipeline(val) {
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
        },
        // [动画]循环Tag清除
        clearItemTag(item) {
            item.tagName = null
            if(item.EmptyAnimation.boolData) {
                item.EmptyAnimation.boolData.tag = null
            }
            if(item.EmptyAnimation.realData) {
                item.EmptyAnimation.realData.tag = null                
            }
            if(item.startParameter.boolData) {
                item.startParameter.boolData.tag = null
            }
            if(item.startParameter.realData) {
                item.startParameter.realData.tag = null                
            }
            if(item.stopParameter.boolData) {
                item.stopParameter.boolData.tag = null
            }
            if(item.stopParameter.realData) {
                item.stopParameter.realData.tag = null                
            }
            this.$forceUpdate()
        },
        // [动画]循环Tag选择
        selectItemTag(item) {
            item.tagDrawer = true
        },
        // 监听循环输入tag值
        changeItemTag(val) {
            console.log(val)
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
            console.log(this.selectCell)
        },
        // Bool运算符选择多个Tag
        btAddTag(arr) {
            arr.push({
                tag: '',
                tagDrawer: false,
                tagRadio: '',
            })
        },
        btDelTag(arr) {
            arr.pop()
        },
        clearBtItemTag(item) {
            item.tag = null
        },
        tagBtItemDrawerOK(item) {
            item.tag = this.tagSelectObj.pointName
            item.tagDrawer = false
        },
        // Real运算符选择多个Tag
        rtAddTag(arr) {
            arr.push({
                tag: '',
                tagDrawer: false,
                tagRadio: '',
            })
        },
        rtDelTag(arr) {
            arr.pop()
        },
        clearRtItemTag(item) {
            item.tag = null
        },
        tagRtItemDrawerOK(item) {
            item.tag = this.tagSelectObj.pointName
            item.tagDrawer = false
        },
        // 勾选Tag
        checkTagChange(row) {
            this.tagSelectObj = row
        },
        tagParameterOK(item) {
            console.log(item)
            if(item.EmptyAnimation.dataType=='Bool') {
                item.EmptyAnimation.boolData.tag = this.tagSelectObj.pointName
            }
            if(item.EmptyAnimation.dataType=='Real') {
                item.EmptyAnimation.realData.tag = this.tagSelectObj.pointName             
            }
            item.EmptyAnimation.tagDrawer = false
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
    @import '../../../../../../css/iotConfigManage/rightDrawer.less';
</style>