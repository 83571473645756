<template>
    <div>
        <div class="tag-con-head" style="margin: 20px 0 10px;">
            <div class="tag-label">文本变化</div>
            <div v-if="item.textChange">
                <el-button type="text" size="mini" @click="aniAddTextChange(item.textChange)">添加</el-button>
                <el-button :style="item.textChange.length==1?'color:#ccc;':'color:red;'" type="text" size="mini" @click="aniDelTextChange(item.textChange)" :disabled="item.textChange.length==1">删除</el-button>
            </div>
        </div>
        <div class="text-change" v-for="(tcItem, tcIndex) in item.textChange" :key="tcIndex">
            <el-form-item label="数据类型">
                <el-select size="mini" v-model="tcItem.dataType" placeholder="请选择数据类型" @change="changeAnimationDataType">
                    <el-option
                        v-for="item in dataType"
                        :key="item"
                        :label="item"
                        :value="item"></el-option>
                </el-select>
            </el-form-item>
            <!-- Bool类型 -->
            <div v-show="tcItem.dataType=='Bool'">
                <el-form-item label="运算符">
                    <el-radio-group v-model="tcItem.boolData.formulaType" @change="changePipeline">
                        <el-radio :label="'empty'">无</el-radio>
                        <el-radio :label="'&'">&</el-radio>
                        <el-radio :label="'||'">||</el-radio>
                        <el-radio :label="'!'">!</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- 运算符为 '无'和'!' -->
                <div class="" style="margin: 10px 0;" v-show="tcItem.boolData.formulaType=='empty'||tcItem.boolData.formulaType=='!'">
                    <div class="flex-change">
                        <el-button v-if="!tcItem.boolData.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectTcItemTag(tcItem)">选择Tag</el-button>
                        <span v-else class="close-lube-btn">
                            {{tcItem.boolData.tag}}
                            <span class="el-icon-error close-lube" @click="clearTcItemTag(tcItem)"></span>
                        </span>
                        <el-input size="mini" v-model="tcItem.boolData.tagValue" style="margin: 5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                    </div>
                    <div>
                        <el-input size="mini" v-model="tcItem.boolData.tagValueDescribe" @change="changeItemTag" placeholder="请输入Tag描述"></el-input>
                    </div>
                </div>
                <!-- 运算符为 '&' -->
                <div v-show="tcItem.boolData.formulaType=='&'">
                    <div class="flex-change">
                        <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                        <div v-if="tcItem.boolData.boolTagData">
                            <el-button type="text" @click="btAddTag(tcItem.boolData.boolTagData)">新增</el-button>
                            <el-button :style="tcItem.boolData.boolTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="tcItem.boolData.boolTagData.length==2" @click="btDelTag(tcItem.boolData.boolTagData)">删除</el-button>
                        </div>
                    </div>
                    <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                        <div style="width:50%;margin-bottom:10px;" v-for="(btItem, btIndex) in tcItem.boolData.boolTagData" :key="btIndex">
                            <div style="display:flex;">
                                <el-button v-if="!btItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(btItem)">选择Tag</el-button>
                                <span v-else class="close-lube-btn">
                                    {{btItem.tag}}
                                    <span class="el-icon-error close-lube" @click="clearBtItemTag(btItem)"></span>
                                </span>
                                <div v-show="tcItem.boolData.boolTagData.length-1!=btIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">&</div>
                            </div>
                                    
                            <!-- 运算符为 '&' 的侧滑选择Tag -->
                            <el-drawer
                                title="选择Tag"
                                :with-header="false"
                                :visible.sync="btItem.tagDrawer"
                                direction="rtl"
                                size="800px">
                                <div class="drawer-title">选择Tag</div>
                                <div class="drawer-content table-tree">
                                <el-table :data="tagTableData" height="700">
                                    <el-table-column label="勾选" width="70">
                                    <template slot-scope="scope">
                                        <el-radio-group v-model="btItem.tagRadio" @change="checkTagChange(scope.row)">
                                        <el-radio :label="scope.row.id">{{''}}</el-radio>
                                        </el-radio-group>
                                    </template>
                                    </el-table-column>
                                    <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                    <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                    <el-table-column prop="pointName" label="点名"></el-table-column>
                                    <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                    <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                    <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                </el-table>
                                </div>
                                <div class="drawer-bottom">
                                <el-button size="small" type="primary" @click="btItem.tagDrawer=false">取消</el-button>
                                <el-button
                                    size="small"
                                    type="primary"
                                    @click="tagBtItemDrawerOK(btItem)"
                                    class="drawer-bottom-ok">确 定</el-button>
                                </div>
                            </el-drawer>
                        </div>
                    </div>
                    <div>
                        <el-input size="mini" v-model="tcItem.boolData.tagValue" style="margin: 5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                    </div>
                    <div>
                        <el-input size="mini" v-model="tcItem.boolData.tagValueDescribe" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag描述"></el-input>
                    </div>
                </div>
                <!-- 运算符为 '||' -->
                <div v-show="tcItem.boolData.formulaType=='||'">
                    <div style="display:flex;justify-content:space-between;align-items:center;">
                        <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                        <div v-if="tcItem.boolData.boolTagData">
                            <el-button type="text" @click="btAddTag(tcItem.boolData.boolTagData)">新增</el-button>
                            <el-button :style="tcItem.boolData.boolTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="tcItem.boolData.boolTagData.length==2" @click="btDelTag(tcItem.boolData.boolTagData)">删除</el-button>
                        </div>
                    </div>
                    <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                        <div style="width:50%;margin-bottom:10px;" v-for="(btItem, btIndex) in tcItem.boolData.boolTagData" :key="btIndex">
                            <div style="display:flex;">
                                <el-button v-if="!btItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(btItem)">选择Tag</el-button>
                                <span v-else class="close-lube-btn">
                                    {{btItem.tag}}
                                    <span class="el-icon-error close-lube" @click="clearBtItemTag(btItem)"></span>
                                </span>
                                <div v-show="tcItem.boolData.boolTagData.length-1!=btIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">||</div>
                            </div>
                            
                            <!-- 运算符为 '||' 的侧滑选择Tag -->
                            <el-drawer
                                title="选择Tag"
                                :with-header="false"
                                :visible.sync="btItem.tagDrawer"
                                direction="rtl"
                                size="800px">
                                <div class="drawer-title">选择Tag</div>
                                <div class="drawer-content table-tree">
                                <el-table :data="tagTableData" height="700">
                                    <el-table-column label="勾选" width="70">
                                    <template slot-scope="scope">
                                        <el-radio-group v-model="btItem.tagRadio" @change="checkTagChange(scope.row)">
                                        <el-radio :label="scope.row.id">{{''}}</el-radio>
                                        </el-radio-group>
                                    </template>
                                    </el-table-column>
                                    <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                    <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                    <el-table-column prop="pointName" label="点名"></el-table-column>
                                    <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                    <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                    <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                </el-table>
                                </div>
                                <div class="drawer-bottom">
                                <el-button size="small" type="primary" @click="btItem.tagDrawer=false">取消</el-button>
                                <el-button
                                    size="small"
                                    type="primary"
                                    @click="tagBtItemDrawerOK(btItem)"
                                    class="drawer-bottom-ok">确 定</el-button>
                                </div>
                            </el-drawer>
                        </div>
                    </div>
                    <div>
                        <el-input size="mini" v-model="tcItem.boolData.tagValue" style="margin:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                    </div>
                    <div>
                        <el-input size="mini" v-model="tcItem.boolData.tagValueDescribe" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag描述"></el-input>
                    </div>
                </div>
            </div>
            <!-- Real类型 -->
            <div v-show="tcItem.dataType=='Real'">
                <el-form-item label="运算符">
                    <el-radio-group v-model="tcItem.realData.formulaType" @change="changePipeline">
                        <el-radio :label="'empty'">无</el-radio>
                        <el-radio :label="'+'">+</el-radio>
                        <el-radio :label="'-'">-</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- 运算符为 '无' -->
                <div class="con-middle-tag-head" style="margin: 10px 0;" v-show="tcItem.realData.formulaType=='empty'">
                    <div>
                        <el-button v-if="!tcItem.realData.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectTcItemTag(tcItem)">选择Tag</el-button>
                        <span v-else class="close-lube-btn">
                            {{tcItem.realData.tag}}
                            <span class="el-icon-error close-lube" @click="clearTcItemTag(tcItem)"></span>
                        </span>
                    </div>
                    <div>
                        <el-input size="mini" v-model="tcItem.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                    </div>
                </div>
                <!-- 运算符为 '+' -->
                <div v-show="tcItem.realData.formulaType=='+'">
                    <div style="display:flex;justify-content:space-between;align-items:center;">
                        <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                        <div v-if="tcItem.realData.realTagData">
                            <el-button type="text" @click="rtAddTag(tcItem.realData.realTagData)">新增</el-button>
                            <el-button :style="tcItem.realData.realTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="tcItem.realData.realTagData.length==2" @click="rtDelTag(tcItem.realData.realTagData)">删除</el-button>
                        </div>
                    </div>
                    <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                        <div style="width:50%;margin-bottom:10px;" v-for="(rtItem, rtIndex) in tcItem.realData.realTagData" :key="rtIndex">
                            <div style="display:flex;">
                                <el-button v-if="!rtItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(rtItem)">选择Tag</el-button>
                                <span v-else class="close-lube-btn">
                                    {{rtItem.tag}}
                                    <span class="el-icon-error close-lube" @click="clearRtItemTag(rtItem)"></span>
                                </span>
                                <div v-show="tcItem.realData.realTagData.length-1!=rtIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">+</div>
                            </div>
                            
                            <!-- 运算符为 '+' 的侧滑选择Tag -->
                            <el-drawer
                                title="选择Tag"
                                :with-header="false"
                                :visible.sync="rtItem.tagDrawer"
                                direction="rtl"
                                size="800px">
                                <div class="drawer-title">选择Tag</div>
                                <div class="drawer-content table-tree">
                                <el-table :data="tagTableData" height="700">
                                    <el-table-column label="勾选" width="70">
                                    <template slot-scope="scope">
                                        <el-radio-group v-model="rtItem.tagRadio" @change="checkTagChange(scope.row)">
                                        <el-radio :label="scope.row.id">{{''}}</el-radio>
                                        </el-radio-group>
                                    </template>
                                    </el-table-column>
                                    <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                    <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                    <el-table-column prop="pointName" label="点名"></el-table-column>
                                    <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                    <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                    <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                </el-table>
                                </div>
                                <div class="drawer-bottom">
                                <el-button size="small" type="primary" @click="rtItem.tagDrawer=false">取消</el-button>
                                <el-button
                                    size="small"
                                    type="primary"
                                    @click="tagRtItemDrawerOK(rtItem)"
                                    class="drawer-bottom-ok">确 定</el-button>
                                </div>
                            </el-drawer>
                        </div>
                    </div>
                    <div>
                    <el-input size="mini" v-model="tcItem.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                    </div>
                </div>
                <!-- 运算符为 '-' -->
                <div v-show="tcItem.realData.formulaType=='-'">
                    <div style="display:flex;justify-content:space-between;align-items:center;">
                        <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                        <div v-if="tcItem.realData.realTagData">
                            <el-button type="text" @click="rtAddTag(tcItem.realData.realTagData)">新增</el-button>
                            <el-button :style="tcItem.realData.realTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="tcItem.realData.realTagData.length==2" @click="rtDelTag(tcItem.realData.realTagData)">删除</el-button>
                        </div>
                    </div>
                    <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                        <div style="width:50%;margin-bottom:10px;" v-for="(rtItem, rtIndex) in tcItem.realData.realTagData" :key="rtIndex">
                            <div style="display:flex;">
                                <el-button v-if="!rtItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(rtItem)">选择Tag</el-button>
                                <span v-else class="close-lube-btn">
                                    {{rtItem.tag}}
                                    <span class="el-icon-error close-lube" @click="clearRtItemTag(rtItem)"></span>
                                </span>
                                <div v-show="tcItem.realData.realTagData.length-1!=rtIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">-</div>
                            </div>
                            
                            <!-- 运算符为 '-' 的侧滑选择Tag -->
                            <el-drawer
                                title="选择Tag"
                                :with-header="false"
                                :visible.sync="rtItem.tagDrawer"
                                direction="rtl"
                                size="800px">
                                <div class="drawer-title">选择Tag</div>
                                <div class="drawer-content table-tree">
                                <el-table :data="tagTableData" height="700">
                                    <el-table-column label="勾选" width="70">
                                    <template slot-scope="scope">
                                        <el-radio-group v-model="rtItem.tagRadio" @change="checkTagChange(scope.row)">
                                        <el-radio :label="scope.row.id">{{''}}</el-radio>
                                        </el-radio-group>
                                    </template>
                                    </el-table-column>
                                    <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                    <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                    <el-table-column prop="pointName" label="点名"></el-table-column>
                                    <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                    <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                    <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                </el-table>
                                </div>
                                <div class="drawer-bottom">
                                <el-button size="small" type="primary" @click="rtItem.tagDrawer=false">取消</el-button>
                                <el-button
                                    size="small"
                                    type="primary"
                                    @click="tagRtItemDrawerOK(rtItem)"
                                    class="drawer-bottom-ok">确 定</el-button>
                                </div>
                            </el-drawer>
                        </div>
                    </div>
                    <div>
                    <el-input size="mini" v-model="tcItem.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                    </div>
                </div>
            </div>
            <el-form-item label="显示状态">
                <el-select size="mini" v-model="tcItem.display" placeholder="请选择显示状态" @change="changeAnimationDataType">
                    <el-option
                        v-for="item in display"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="文本颜色">
                <el-input size="mini" v-model="tcItem.textColor" placeholder="请输入16进制颜色值" @change="changeAnimationDataType"></el-input>
            </el-form-item>
            <!-- '文本变化' 的侧滑选择Tag -->
            <el-drawer
                title="选择Tag"
                :with-header="false"
                :visible.sync="tcItem.tagDrawer"
                direction="rtl"
                size="800px">
                <div class="drawer-title">选择Tag</div>
                <div class="drawer-content table-tree">
                <el-table :data="tagTableData" height="700">
                    <el-table-column label="勾选" width="70">
                    <template slot-scope="scope">
                        <el-radio-group v-model="tcItem.tagRadio" @change="checkTagChange(scope.row)">
                        <el-radio :label="scope.row.id">{{''}}</el-radio>
                        </el-radio-group>
                    </template>
                    </el-table-column>
                    <el-table-column prop="number" label="序号" width="50"></el-table-column>
                    <el-table-column prop="dataType" label="数据类型"></el-table-column>
                    <el-table-column prop="pointName" label="点名"></el-table-column>
                    <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                    <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                    <el-table-column prop="cycle" label="采集周期"></el-table-column>
                </el-table>
                </div>
                <div class="drawer-bottom">
                <el-button size="small" type="primary" @click="tcItem.tagDrawer=false">取消</el-button>
                <el-button
                    size="small"
                    type="primary"
                    @click="tagTcItemDrawerOK(tcItem)"
                    class="drawer-bottom-ok">确 定</el-button>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dataType: ['Bool','Real'],//文本 -> 数据类型
            display: [//动画结束状态
                {code: 'hide', name: '隐藏' },
                {code: 'keep', name: '保持' },
            ],
            tagSelectObj: {},
        }
    },
    props: {
        selectCell:{
            type: String | Object
        },
        drawerNode: {
            type: Object
        },
        tagTableData: {
            type: Array
        },
        item: {
            type: Object
        }
    },
    methods: {
        // 文本动画添加项
        aniAddTextChange(arr) {
            arr.push({
                dataType: '',
                boolData: {
                    formulaType: 'empty',
                    tag: '',
                    tagValue: '',
                    tagValueDescribe: '',
                    boolTagData: [
                        {
                            tag: '',
                            tagDrawer: false,
                            tagRadio: '',
                        },
                        {
                            tag: '',
                            tagDrawer: false,
                            tagRadio: '',
                        }
                    ]
                },
                realData: {
                    formulaType: 'empty',
                    tag: '',
                    unit: '',
                    realTagData: [
                        {
                            tag: '',
                            tagDrawer: false,
                            tagRadio: '',
                        },
                        {
                            tag: '',
                            tagDrawer: false,
                            tagRadio: '',
                        }
                    ]
                },
                textColor: '',
                display: '',
                tagDrawer: false,
                tagRadio: '',  
            })
        },
        // 文本动画删除项
        aniDelTextChange(arr) {
            arr.pop()
        },
        changeAnimationDataType(val) {
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
        },
        changePipeline(val) {
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
        },
        // [动效-文本变化]循环选择Tag
        clearTcItemTag(item) {
            if(item.dataType=='Bool') {
                item.boolData.tag = null
            }
            if(item.dataType=='Real') {
                item.realData.tag = null
            }
        },
        selectTcItemTag(item) {
            item.tagDrawer = true
        },
        // 监听循环输入tag值
        changeItemTag(val) {
            console.log(val)
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
            console.log(this.selectCell)
        },
        // [动画]循环Tag选择
        selectItemTag(item) {
            item.tagDrawer = true
        },
        // Bool运算符选择多个Tag
        btAddTag(arr) {
            arr.push({
                tag: '',
                tagDrawer: false,
                tagRadio: '',
            })
        },
        btDelTag(arr) {
            arr.pop()
        },
        clearBtItemTag(item) {
            item.tag = null
        },
        tagBtItemDrawerOK(item) {
            item.tag = this.tagSelectObj.pointName
            item.tagDrawer = false
        },
        // Real运算符选择多个Tag
        rtAddTag(arr) {
            arr.push({
                tag: '',
                tagDrawer: false,
                tagRadio: '',
            })
        },
        rtDelTag(arr) {
            arr.pop()
        },
        clearRtItemTag(item) {
            item.tag = null
        },
        tagRtItemDrawerOK(item) {
            item.tag = this.tagSelectObj.pointName
            item.tagDrawer = false
        },
        // [动效-文本变化]循环选择Tag
        clearTcItemTag(item) {
            if(item.dataType=='Bool') {
                item.boolData.tag = null
            }
            if(item.dataType=='Real') {
                item.realData.tag = null
            }
        },
        selectTcItemTag(item) {
            item.tagDrawer = true
        },
        tagTcItemDrawerOK(item) {
            if(item.dataType=='Bool') {
                item.boolData.tag = this.tagSelectObj.pointName
            }
            if(item.dataType=='Real') {
                item.realData.tag = this.tagSelectObj.pointName             
            }
            item.tagDrawer = false
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
        },
        // 勾选Tag
        checkTagChange(row) {
            this.tagSelectObj = row
        },
    }
}
</script>
<style lang="less" scoped>
    @import '../../../../../../css/iotConfigManage/rightDrawer.less';
</style>