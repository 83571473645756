<template>
    <div>
        <!-- <div class="left-search">
            <el-input v-model="searchValue" placeholder="搜索图形" suffix-icon="el-icon-search"/>
        </div> -->
        <el-collapse v-model="collapseValue">
            <el-collapse-item title="通用" name="baseShape">
                <div class="btn-group">
                    <div class="btn left-shape rect" title="矩形" @mousedown="startDrag('Rect',$event)">
                        <span></span>
                    </div>
                    <div class="btn left-shape rectry" title="圆角矩形" @mousedown="startDrag('RectRy',$event)">
                        <span></span>
                    </div>
                    <div class="btn left-shape square" title="正方形" @mousedown="startDrag('Square',$event)">
                        <span></span>
                    </div>
                    <div class="btn left-shape circle" title="圆形" @mousedown="startDrag('Circle',$event)">
                        <span></span>
                    </div>
                    <div class="btn left-shape ellipse" title="椭圆" @mousedown="startDrag('Ellipse',$event)">
                        <span></span>
                    </div>
                    <div class="btn left-shape polygon" title="菱形" @mousedown="startDrag('diamond',$event)">
                        <span></span>
                    </div>
                    <div class="svg-shape" title="三角形" @mousedown="startDrag('triangle',$event)">
                        <svg width="40" height="40">
                        <polygon points="20,0 0,39 39,39" style="fill:#FFF;stroke:#000;stroke-width:1px;"></polygon>
                        </svg>
                    </div>
                    <div class="svg-shape" title="平行四边形" @mousedown="startDrag('parallelogram',$event)">
                        <svg width="40" height="40">
                        <polygon points="10,5 39,5 30,35 1,35" style="fill:#FFF;stroke:#000;stroke-width:1px;"></polygon>
                        </svg>
                    </div>
                    <div class="svg-shape" title="六边形" @mousedown="startDrag('hexagon',$event)">
                        <svg width="40" height="40">
                        <polygon points="20,1 39,10 39,30 20,40 1,30 1,10" style="fill:#FFF;stroke:#000;stroke-width:1px;"></polygon>
                        </svg>
                    </div>
                    <div class="svg-shape" title="梯形" @mousedown="startDrag('trapezoid',$event)">
                        <svg width="40" height="40">
                        <polygon points="8,7 32,7 39,37 1,37" style="fill:#FFF;stroke:#000;stroke-width:1px;"></polygon>
                        </svg>
                    </div>
                    <!-- <div class="svg-shape" title="虚线" @mousedown="startDrag('trapezoid',$event)">
                        <svg width="40" height="40">
                        <line x1="0" y1="5" x2="100" y2="5" stroke-dasharray="5 2" stroke="#000"/>
                        </svg>
                    </div> -->
                </div>
            </el-collapse-item>
            <!-- <el-collapse-item title="按钮组件" name="button">
                <div class="btn-group">
                <div class="svg-shape" title="按钮" @mousedown="startDrag('button',$event)">
                    <svg width="40" height="40">
                    <rect x="1" y="5" width="38" height="20" rx="5" style="fill:#409EFF;stroke:#409EFF;stroke-width:1;"></rect>
                    <text x="20" y="19" fill="#fff" font-size="12" text-anchor="middle">按钮</text>
                    </svg>
                </div>
                <div class="svg-shape" title="开关" @mousedown="startDrag('switch',$event)">
                    <svg width="40" height="40">
                    <rect x="1" y="5" width="38" height="20" rx="10" style="fill:#dcdfe6;stroke:#dcdfe6;stroke-width:1;"></rect>
                    <circle cx="12" cy="15" r="8" fill="#FFF"></circle>
                    </svg>
                </div>
                </div>
            </el-collapse-item> -->
            <el-collapse-item title="组件" name="lineImg">
                <div class="btn-group">
                    <div class="btn left-shape line-water" title="虚线" @mousedown="startDrag('lineWater',$event)">
                        <span></span>
                    </div>
                    <div class="btn left-shape rect" title="液位" @mousedown="startDrag('level',$event)">
                        <span>
                        <i style="display:block;width:100%;height:18px;background:#2d8cf0;border:0;margin-top:12px;"></i>
                        </span>
                    </div>
                </div>
            </el-collapse-item>
            <el-collapse-item title="设备" name="deviceImg">
                <div class="btn-group">
                    <div v-for="(item,index) in resourceData" :key="index" class="btn left-shape images water-pump-img" :title="item.resourceName" @mousedown="startDrag('waterPumpImg',$event,item.fileUrl,index+1)">
                        <el-image :src="item.fileUrl" fit="contain"></el-image>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
<script>
import {startDragToGraph} from '@/js/Graph/methods.js'
export default {
    data() {
        return {
            searchValue: '',
            collapseValue: ['baseShape','lineImg','deviceImg'],
        }
    },
    props: {
        graph:{
            type: String | Object
        },
        resourceData: {
            type: Array
        }
    },
    methods: {
        // 拖拽生成正方形或者圆形
        startDrag(type,e,fileUrl,index){
            startDragToGraph(this.graph,type,e,fileUrl,index)
        }
    }
}
</script>
<style lang="less" scoped>
.el-collapse-item {
  box-shadow: 0 0 0;
  border-radius: 0;
  padding: 0;
  margin: 0 0 5px;
}
.el-collapse-item /deep/.el-collapse-item__header {
    font-size: 14px;
    color: #000;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 20px;
    box-sizing: border-box;
    transition: all .3s ease;
    background-color: #f2f2f2;
}
.el-collapse-item /deep/.el-collapse-item__header:hover {
    background-color: #e5e5e5;
}
/deep/.el-collapse-item__wrap {
    background: #FFF;
}
</style>