import '@antv/x6-vue-shape';
import { Addon } from '@antv/x6';
// 拖拽生成图形
export const startDragToGraph  = (graph,type,e,fileUrl,index) =>{
    const node = 
    type === 'Rect' ? graph.createNode({//创建矩形节点
      width: 120,
      height: 50,
      markup: [
        {
          tagName: 'rect',
          selector: 'body',
        },
        {
          tagName: 'text',
          selector: 'label',
        },
        // {
        //   tagName: 'image',
        //   selector: 'image',
        // },
        // {
        //   tagName: 'g',
        //   selector: 'tipGroup',
        //   attrs: {
        //     'pointer-events': 'visiblePainted',
        //   },
        //   children: [
        //     {
        //       tagName: 'rect',
        //       selector: 'tipPath',
        //     },
        //     {
        //       tagName: 'text',
        //       selector: 'tipText',
        //     },
        //     {
        //       tagName: 'text',
        //       selector: 'zljText',
        //     },
        //     {
        //       tagName: 'text',
        //       selector: 'fljText',
        //     },
        //     {
        //       tagName: 'text',
        //       selector: 'tipOpenBtn',
        //     },
        //   ],
        // },
      ],
      attrs: {
        label: {
          text: '',
          fill: '#000000',
          fontSize: 14,
          textWrap: {
            width: -10,
            height: -10,
            ellipsis: true,
          },
        },
        body: {
          stroke: '#000000',
          strokeWidth: 1,
          fill: '#ffffff',
        },
        // image: {
        //   xlinkHref: 'https://gw.alipayobjects.com/zos/bmw-prod/2010ac9f-40e7-49d4-8c4a-4fcf2f83033b.svg', //在线图片
        //   href: require('@/img/complete.png'), //本地图片
        //   width: 16,
        //   height: 16,
        //   x: 12,
        //   y: 12,
        //   textAnchor: 'middle',
        //   textVerticalAnchor: 'top',
        // },
        // tipGroup: {
        //   refX: '0%',
        //   refY: '-170%',
        //   display: 'block',
        // },
        // tipPath: {
        //   d: 'M 5 5 5 5 5 25 150 25 150 -25 5 -25 5 -5 Z',
        //   width: 120,
        //   height: 80,
        //   fill: 'rgba(255,255,255,0.7)',
        //   stroke: '#135fff',
        //   strokeWidth: 1,
        //   event: 'node:collapse',
        // },
        // tipText: {
        //   refX: '55%',
        //   refY: '20%',
        //   fill: '#595959',
        //   text: '提示信息',
        //   fontSize: '12px',
        //   fontFamily: 'Source Han Sans CN',
        //   fontWeight: 400,
        // },
        // zljText: {
        //   text: '',
        //   fontSize: '12px',
        // },
        // fljText: {
        //   text: '',
        //   fontSize: '12px',
        // },
        // tipOpenBtn: {
        //   fill: '#00b894',
        //   refX: '50%',
        //   refY: '120%',
        //   text: '开启',
        //   fontSize: '12px',
        //   cursor: 'pointer',
        //   event: 'node:rectclick',
        // }
      },
      ports: ports
    })
  : type === 'RectRy' ? graph.createNode({//创建圆角矩形节点
      width: 120,
      height: 50,
      attrs: {
        label: {
          text: '',
          fill: '#000000',
          fontSize: 14,
          textWrap: {
            width: -10,
            height: -10,
            ellipsis: true,
          },
        },
        body: {
          stroke: '#000000',
          strokeWidth: 1,
          fill: '#ffffff',
          rx: 10,
          ry: 10,
        }
      },
      ports: ports
    })
  : type === 'Square' ? graph.createNode({//创建正方形节点
      width: 70,
      height: 70,
      attrs: {
        label: {
          text: '',
          fill: '#000000',
          fontSize: 14,
          textWrap: {
            width: -10,
            height: -10,
            ellipsis: true,
          },
        },
        body: {
          stroke: '#000000',
          strokeWidth: 1,
          fill: '#ffffff',
        }
      },
      ports: ports
    })
  : type === 'Circle' ? graph.createNode({//创建圆形节点
      shape: 'circle',
      width: 70,
      height: 70,
      attrs: {
        label: {
          text: '',
          fill: '#000000',
          fontSize: 14,
          textWrap: {
            width: -20,
            height: -10,
            ellipsis: true,
          },
        },
        body: {
          stroke: '#000000',
          strokeWidth: 1,
          fill: '#ffffff',
        }
      },
      ports: ports
    })
  : type === 'Ellipse' ? graph.createNode({//创建椭圆节点
      shape: 'ellipse',
      width: 100,
      height: 70,
      attrs: {
        label: {
          text: '',
          fill: '#000000',
          fontSize: 14,
          textWrap: {
            width: -20,
            height: -10,
            ellipsis: true,
          },
        },
        body: {
          stroke: '#000000',
          strokeWidth: 1,
          fill: '#ffffff',
        }
      },
      ports: ports
    })
  : type === 'diamond' ? graph.createNode({//创建菱形节点
      shape: 'polygon',
      width: 80,
      height: 80,
      attrs: {
        label: {
          text: '',
          fill: '#000000',
          fontSize: 14,
          textWrap: {
            width: -50,
            height: '70%',
            ellipsis: true,
          },
        },
        body: {
          fill: '#ffffff',
          stroke: '#000000',
          refPoints: '0,10 10,0 20,10 10,20',
          strokeWidth: 1,
        }
      },
      ports: ports
    })
  : type === 'triangle' ? graph.createNode({//创建三角形节点
    shape: 'polygon',
    width: 80,
    height: 80,
    attrs: {
      label: {
        text: '',
        fill: '#000000',
        fontSize: 14,
        textWrap: {
          width: -50,
          height: '70%',
          ellipsis: true,
        },
      },
      body: {
        fill: '#ffffff',
        stroke: '#000000',
        refPoints: '20,0 0,39 39,39',
        strokeWidth: 1,
      }
    },
    ports: ports
  })    
  : type === 'parallelogram' ? graph.createNode({//创建平行四边形节点
    shape: 'polygon',
    width: 100,
    height: 60,
    attrs: {
      label: {
        text: '',
        fill: '#000000',
        fontSize: 14,
        textWrap: {
          width: -50,
          height: '70%',
          ellipsis: true,
        },
      },
      body: {
        fill: '#ffffff',
        stroke: '#000000',
        refPoints: '10,5 39,5 30,35 1,35',
        strokeWidth: 1,
      }
    },
    ports: ports
  })  
  : type === 'hexagon' ? graph.createNode({//创建六边形节点
    shape: 'polygon',
    width: 80,
    height: 80,
    attrs: {
      label: {
        text: '',
        fill: '#000000',
        fontSize: 14,
        textWrap: {
          width: -50,
          height: '70%',
          ellipsis: true,
        },
      },
      body: {
        fill: '#ffffff',
        stroke: '#000000',
        refPoints: '20,1 39,10 39,30 20,40 1,30 1,10',
        strokeWidth: 1,
      }
    },
    ports: ports
  })  
  : type === 'trapezoid' ? graph.createNode({//创建梯形节点
    shape: 'polygon',
    width: 80,
    height: 40,
    attrs: {
      label: {
        text: '',
        fill: '#000000',
        fontSize: 14,
        textWrap: {
          width: -50,
          height: '70%',
          ellipsis: true,
        },
      },
      body: {
        fill: '#ffffff',
        stroke: '#000000',
        refPoints: '8,7 32,7 39,37 1,37',
        strokeWidth: 1,
      }
    },
    ports: ports
  })
  : type === 'button' ? graph.createNode({//创建button节点
    shape: 'rect',
    width: 80,
    height: 40,
    attrs: {
      label: {
        text: '按钮',
        fill: '#fff',
        fontSize: 14,
        textWrap: {
          width: -50,
          height: '70%',
          ellipsis: true,
        },
      },
      body: {
        fill: '#409EFF',
        stroke: '#409EFF',
        strokeWidth: 1,
        rx: '10',
      }
    },
    ports: ports
  })
  : type === 'switch' ? graph.createNode({//创建switch节点
    shape: 'rect',
    width: 60,
    height: 30,
    markup: [
      {
        tagName: 'rect',
        selector: 'rect',
      },
      {
        tagName: 'circle',
        selector: 'circle',
      }
    ],
    attrs: {
      rect: {
        width: 60,
        height: 30,
        fill: '#dcdfe6',
        stroke: '#dcdfe6',
        strokeWidth: 0,
        rx: 15,
      },
      circle: {
        fill: '#fff',
        cx: 15,
        cy: 15,
        r: 10,
      }
    },
    ports: ports
  })
  : type === 'lineWater' ? graph.createNode({//创建lineWater节点
    shape: 'polyline',
    width: 500,
    height: 5,
    markup: [
      {
        tagName: 'svg',
        selector: 'svgele',
        children: [
          {
            tagName: 'polyline',
            selector: 'waterline1',
          },
          {
            tagName: 'polyline',
            selector: 'waterline2',
          },
        ],
      },
    ],
    attrs: {
      svgele: {
        width: 500,
        height: 5,
      },
      waterline1: {
        refPoints: '0,5 100,5',
        strokeDasharray: '20 10',
        stroke: '#24D200',//#63FFC5
        strokeWidth: 8,
        fill: 'none',
        style: {
          'transform':'translate(0px, 0px)',
        },
        class: 'line-water-show',
      },
      waterline2: {
        refPoints: '0,5 100,5',
        strokeDasharray: '20 10',
        stroke: '#24D200',
        strokeWidth: 8,
        fill: 'none',
        style: {
          'transform':'translate(-510px, 0px)',
        },
        class: 'line-water-hide',
      },
    },
  })
  : type === 'level' ? graph.createNode({//创建液位节点
    shape: 'rect',
    width: 110,
    height: 100,
    markup: [
      {
        tagName: 'svg',
        selector: 'levelSvg',
        children: [
          {
            tagName: 'rect',
            selector: 'levelRect',
          },
          {
            tagName: 'rect',
            selector: 'levelRect2',
          },
        ],
      },
    ],
    attrs: {
      levelSvg: {
        width: 110,
        height: 100,
      },
      levelRect: {
        width: 110,
        height: 100,
        strokeWidth: 0,
        fill: 'rgba(255,255,255,0)',
      },
      levelRect2: {
        width: 110,
        height: 100,
        strokeWidth: 0,
        fill: '#2d8cf0',
        style: {
          'transform': 'translateY(90px)'
        },
      },
    },
  })
  : type === 'waterPumpImg' ? graph.createNode({//创建图片节点
    shape: 'image',
    x: 40,
    y: 40,
    width: 100,
    height: 100,
    markup: [
      {
        tagName: 'rect',
        selector: 'body',
      },
      {
        tagName: 'image',
      },
      {
        tagName: 'text',
        selector: 'label',
      },
    ],
    attrs: {
      label: {
        text: '',
        fill: '#fff',
        fontSize: 14,
      },
      body: {
        fill: '#fff',
        stroke: '#fff',
      },
      image: {
        width: 100,
        height: 100,
        refX: 0,
        refY: 0,
        xlinkHref: fileUrl,
        class: 'water-pump-img'+index,
      }
    },
    ports: ports
  })
  : graph.createNode({//创建图片节点
    shape: 'image',
    x: 40,
    y: 40,
    width: 100,
    height: 100,
    markup: [
      {
        tagName: 'rect',
        selector: 'body',
      },
      {
        tagName: 'text',
        selector: 'label',
      },
    ],
    attrs: {
      label: {
        text: '',
        fill: '#fff',
        fontSize: 14,
      },
      body: {
        fill: '#fff',
        stroke: '#fff',
      },
    },
    ports: ports
  })
  const dnd = new Addon.Dnd({target:graph})
  dnd.start(node,e)
}
const ports = {
    groups: {
      // 输入链接桩群组定义
      top: {
        position: 'top',
        attrs: {
          circle: {
            r: 4,
            magnet: true,
            stroke: '#2D8CF0',
            strokeWidth: 2,
            fill: '#fff',
          },
        },
      },
      // 输出链接桩群组定义
      bottom: {
        position: 'bottom',
        attrs: {
          circle: {
            r: 4,
            magnet: true,
            stroke: '#2D8CF0',
            strokeWidth: 2,
            fill: '#fff',
          },
        },
      },
      left: {
        position: 'left',
        attrs: {
          circle: {
            r: 4,
            magnet: true,
            stroke: '#2D8CF0',
            strokeWidth: 2,
            fill: '#fff',
          },
        },
      },
      right: {
        position: 'right',
        attrs: {
          circle: {
            r: 4,
            magnet: true,
            stroke: '#2D8CF0',
            strokeWidth: 2,
            fill: '#fff',
          },
        },
      },
    },
    items: [
      {
        id: 'port1',
        group: 'top',
      },
      {
        id: 'port2',
        group: 'bottom',
      },
      {
        id: 'port3',
        group: 'left',
      },
      {
        id: 'port4',
        group: 'right',
      }
    ],
}