<template>
    <div style="margin: 10px 0;">
        <div>
            <el-button v-if="!item.tagName" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(item)">选择Tag</el-button>
            <span v-else class="close-lube-btn">
                {{item.tagName}}
                <span class="el-icon-error close-lube" @click="clearItemTag(item)"></span>
            </span>
        </div>
        <div v-show="item.animation=='Empty'||item.animation=='PipelineFlow'">
            <el-input size="mini" v-model="item.tagValue" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
        </div>
        <el-form-item label="最大值">
            <el-input size="mini" v-model="item.waterLevel.max" placeholder="请输入液位最大值" @change="changeWaterLevelMax"></el-input>
        </el-form-item>
        <el-form-item label="默认值">
            <el-input size="mini" v-model="item.waterLevel.defaultValue" placeholder="请输入液位最小值" @change="changeWaterLevelMini"></el-input>
        </el-form-item>
        <el-drawer
            title="选择Tag"
            :with-header="false"
            :visible.sync="item.tagDrawer"
            direction="rtl"
            size="800px">
            <div class="drawer-title">选择Tag</div>
            <div class="drawer-content table-tree">
            <el-table :data="tagTableData" height="700">
                <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                    <el-radio-group v-model="item.tagRadio" @change="checkTagChange(scope.row)">
                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                    </el-radio-group>
                </template>
                </el-table-column>
                <el-table-column prop="number" label="序号" width="50"></el-table-column>
                <el-table-column prop="dataType" label="数据类型"></el-table-column>
                <el-table-column prop="pointName" label="点名"></el-table-column>
                <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                <el-table-column prop="cycle" label="采集周期"></el-table-column>
            </el-table>
            </div>
            <div class="drawer-bottom">
            <el-button size="small" type="primary" @click="item.tagDrawer=false">取消</el-button>
            <el-button
                size="small"
                type="primary"
                @click="tagItemDrawerOK(item)"
                class="drawer-bottom-ok">确 定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tagSelectObj: {},
        }
    },
    props: {
        selectCell:{
            type: String | Object
        },
        drawerNode: {
            type: Object
        },
        tagTableData: {
            type: Array
        },
        item: {
            type: Object
        }
    },
    methods: {
        // [动画]循环Tag清除
        clearItemTag(item) {
            item.tagName = null
            if(item.EmptyAnimation.boolData) {
                item.EmptyAnimation.boolData.tag = null
            }
            if(item.EmptyAnimation.realData) {
                item.EmptyAnimation.realData.tag = null                
            }
            if(item.startParameter.boolData) {
                item.startParameter.boolData.tag = null
            }
            if(item.startParameter.realData) {
                item.startParameter.realData.tag = null                
            }
            if(item.stopParameter.boolData) {
                item.stopParameter.boolData.tag = null
            }
            if(item.stopParameter.realData) {
                item.stopParameter.realData.tag = null                
            }
            this.$forceUpdate()
        },
        // [动画]循环Tag选择
        selectItemTag(item) {
            item.tagDrawer = true
        },
        // 监听循环输入tag值
        changeItemTag(val) {
            console.log(val)
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
            console.log(this.selectCell)
        },
        // 监听循环输入液位参数最大值
        changeWaterLevelMax(val) {
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
            console.log(this.selectCell)
        },
        // 监听循环输入液位参数最小值
        changeWaterLevelMini(val) {
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
            console.log(this.selectCell)
        },
        // 勾选Tag
        checkTagChange(row) {
            this.tagSelectObj = row
        },
        // [动画]循环Tag确认
        tagItemDrawerOK(item) {
            item.tagName = this.tagSelectObj.pointName
            if(item.EmptyAnimation.boolData) {
                item.EmptyAnimation.boolData.tag = this.tagSelectObj.pointName
            }
            if(item.EmptyAnimation.realData) {
                item.EmptyAnimation.realData.tag = this.tagSelectObj.pointName             
            }
            if(item.startParameter.boolData) {
                item.startParameter.boolData.tag = this.tagSelectObj.pointName
            }
            if(item.startParameter.realData) {
                item.startParameter.realData.tag = this.tagSelectObj.pointName             
            }
            if(item.stopParameter.boolData) {
                item.stopParameter.boolData.tag = this.tagSelectObj.pointName
            }
            if(item.stopParameter.realData) {
                item.stopParameter.realData.tag = this.tagSelectObj.pointName             
            }
            item.tagDrawer = false
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
            console.log(this.selectCell)
        },
    }
}
</script>
<style lang="less" scoped>
    @import '../../../../../../css/iotConfigManage/rightDrawer.less';
</style>