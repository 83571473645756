import { importEmpty } from './importConfigSub/empty'
import { importPipelineFlowStart } from './importConfigSub/pipelineFlowStart'
import { importPipelineFlowStop } from './importConfigSub/pipelineFlowStop'
import { importTextChange } from './importConfigSub/textChange'
import { importBackground } from './importConfigSub/background'
import { importRotate } from './importConfigSub/rotate'
/**
 * 导入组态数据
 */
export const importConfig = (graph) => {
    if (sessionStorage.getItem('configCellsData')) {
        let cells = sessionStorage.getItem('configCellsData')
        let cellsParse = JSON.parse(cells)
        cellsParse.cells.forEach(v => {
            if (v.data) {//如果已关联设备
                if (v.data.graphical.customAnimations) {//动画
                    let customAnimationss = []
                    v.data.graphical.customAnimations.forEach(item => {
                        let tagName = ''
                        let tagValue = ''
                        let waterLevel = {
                            max: '',
                            defaultValue: ''
                        }
                        let EmptyAnimation = {
                            dataType: '',
                            boolData: {
                                formulaType: 'empty',
                                tag: '',
                                tagValue: '',
                                boolTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            realData: {
                                formulaType: 'empty',
                                tag: '',
                                unit: '',
                                realTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            tagDrawer: false,
                            tagRadio: '',
                        }
                        let startParameter = {
                            dataType: '',
                            boolData: {
                                formulaType: 'empty',
                                tag: '',
                                tagValue: '',
                                boolTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            realData: {
                                formulaType: 'empty',
                                tag: '',
                                unit: '',
                                realTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            tagDrawer: false,
                            tagRadio: '',
                        }
                        let stopParameter = {
                            display: '',
                            dataType: '',
                            boolData: {
                                formulaType: 'empty',
                                tag: '',
                                tagValue: '',
                                boolTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            realData: {
                                formulaType: 'empty',
                                tag: '',
                                unit: '',
                                realTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            tagDrawer: false,
                            tagRadio: '',
                        }
                        let textChange = [
                            {
                                dataType: '',
                                boolData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    tagValue: '',
                                    tagValueDescribe: '',
                                    boolTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                realData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    unit: '',
                                    realTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                textColor: '',
                                display: '',
                                tagDrawer: false,
                                tagRadio: '',  
                            }
                        ]
                        let background = [
                            {
                                dataType: '',
                                boolData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    tagValue: '',
                                    tagValueDescribe: '',
                                    boolTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                realData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    unit: '',
                                    realTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                backgroundType: '',
                                backgroundFilePath: '',
                                backgroundColor: '',
                                tagDrawer: false,
                                tagRadio: '',  
                                bgImgDrawer: false,
                                bgImgRadio: '',
                            }
                        ]
                        let imgRotate = {
                            startParameter: {
                                dataType: '',
                                boolData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    tagValue: '',
                                    boolTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                realData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    unit: '',
                                    realTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                tagDrawer: false,
                                tagRadio: '',
                            },
                            stopParameter: {
                                display: '',
                                dataType: '',
                                boolData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    tagValue: '',
                                    boolTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                realData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    unit: '',
                                    realTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                tagDrawer: false,
                                tagRadio: '',
                            },
                        }
                        let animationRate = {
                            rateType: null,
                            manualRate: null,
                            relativeTag: null,
                            relativeTagValue: null,
                        }
                        if (item.animation == 'Empty') {//无动画
                            EmptyAnimation = {}
                            EmptyAnimation = importEmpty(item,EmptyAnimation)
                        }
                        if (item.animation == 'PipelineFlow') {//管道流水
                            startParameter = {}
                            stopParameter = {}
                            startParameter = importPipelineFlowStart(item,startParameter)
                            stopParameter = importPipelineFlowStop(item,stopParameter)
                            animationRate = item.pipelineFlow.animationRate?item.pipelineFlow.animationRate:animationRate
                        }
                        if (item.animation == 'waterLevel') {//液位
                            waterLevel = {}
                            tagName = item.waterLevel.tag
                            waterLevel = {
                                max: item.waterLevel.max,
                                defaultValue: item.waterLevel.defaultValue,
                            }
                        }
                        if (item.animation == 'textChange') {//文本变化
                            textChange = []
                            textChange = importTextChange(item,textChange)
                        }
                        if (item.animation == 'background') {//背景变化
                            background = []
                            background = importBackground(item,background)
                        }
                        if (item.animation == 'rotate') {//旋转
                            imgRotate = {}
                            imgRotate = importRotate(item,imgRotate)
                            animationRate = item.imgRotate.animationRate?item.imgRotate.animationRate:animationRate
                        }
                        let tagObj = {
                            animation: item.animation,
                            withoutData: item.withoutData,
                            startParameter: startParameter,
                            stopParameter: stopParameter,
                            EmptyAnimation: EmptyAnimation,
                            textChange: textChange,
                            background: background,
                            imgRotate: imgRotate,
                            animationRate: animationRate,
                            tagName: tagName,
                            tagValue: tagValue,
                            waterLevel: waterLevel,
                            tagDrawer: false,
                            tagRadio: '',
                        }
                        customAnimationss.push(tagObj)
                    })
                    v.data.graphical.customAnimations = customAnimationss
                }
                if (v.data.graphical.textItems) {//文本
                    let textItems = []
                    v.data.graphical.textItems.forEach(v => {
                        let boolData = {}
                        let realData = {}
                        if (v.boolDataContent) {//Bool类型文本
                            let tag = ''
                            let boolTagData = []
                            let values = []
                            for (let i in v.boolDataContent.values) {
                                values.push({
                                    code: i,
                                    meaning: v.boolDataContent.values[i],
                                })
                            }
                            if (v.boolDataContent.formulaType == 'empty') {//Bool类型 运算符为 '无'
                                tag = v.boolDataContent.tag
                                boolTagData = [{
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }]
                            }
                            if (v.boolDataContent.formulaType == '&') {//Bool类型 运算符为 '&'
                                tag = ''
                                let arr = v.boolDataContent.tag.split('&')
                                arr.forEach(arrv => {
                                    boolTagData.push({
                                        tag: arrv,
                                        tagDrawer: false,
                                        tagRadio: '',
                                    })
                                })
                            }
                            if (v.boolDataContent.formulaType == '||') {//Bool类型 运算符为 '||'
                                tag = ''
                                let arr = v.boolDataContent.tag.split('||')
                                arr.forEach(arrv => {
                                    boolTagData.push({
                                        tag: arrv,
                                        tagDrawer: false,
                                        tagRadio: '',
                                    })
                                })
                            }
                            if (v.boolDataContent.formulaType == '!') {//Bool类型 运算符为 '!'
                                tag = v.boolDataContent.tag
                                boolTagData = [{
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }]
                            }
                            boolData = {
                                formulaType: v.boolDataContent.formulaType,
                                tag: tag,
                                unit: v.boolDataContent.unit,
                                tagDescribe: v.boolDataContent.tagDescribe,
                                values: values,
                                boolTagData: boolTagData
                            }
                            realData = {
                                formulaType: 'empty',
                                tag: "",
                                unit: "",
                                tagDescribe: "",
                                realTagData: [{
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }],
                            }
                        }
                        if (v.realDataContent) {//Real类型文本
                            let tag = ''
                            let realTagData = []
                            if (v.realDataContent.formulaType == 'empty') {//Real类型 运算符为 '无'
                                tag = v.realDataContent.tag
                                realTagData = [{
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }]
                            }
                            if (v.realDataContent.formulaType == '+') {//Real类型 运算符为 '+'
                                tag = ''
                                let arr = v.realDataContent.tag.split('+')
                                arr.forEach(arrv => {
                                    realTagData.push({
                                        tag: arrv,
                                        tagDrawer: false,
                                        tagRadio: '',
                                    })
                                })
                            }
                            if (v.realDataContent.formulaType == '-') {//Real类型 运算符为 '-'
                                tag = ''
                                let arr = v.realDataContent.tag.split('-')
                                arr.forEach(arrv => {
                                    realTagData.push({
                                        tag: arrv,
                                        tagDrawer: false,
                                        tagRadio: '',
                                    })
                                })
                            }
                            boolData = {
                                formulaType: 'empty',
                                tag: "",
                                unit: "",
                                tagDescribe: "",
                                values: [{
                                    code: '',
                                    meaning: ''
                                }],
                                boolTagData: [{
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }],
                            }
                            realData = {
                                formulaType: v.realDataContent.formulaType,
                                tag: tag,
                                unit: v.realDataContent.unit,
                                tagDescribe: v.realDataContent.tagDescribe,
                                realTagData: realTagData
                            }
                        }
                        let tagObj = {
                            dataType: v.dataType,
                            boolData: boolData,
                            realData: realData,
                            textItemsDrawer: false,
                            textItemsRadio: '',
                        }
                        textItems.push(tagObj)
                    })
                    v.data.graphical.textItems = textItems
                }
            }
        })
        graph.fromJSON(cellsParse.cells)
        console.log(cellsParse.cells)
    }
}