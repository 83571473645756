<template>
    <div>
        <div class="btn-group">
            <el-tooltip content="保存数据" placement="bottom">
                <div class="btn hbtn" style="line-height: 35px;" @click="exportToJSON()">
                    <i class="iconfont icon-baocun"></i>
                </div>
            </el-tooltip> 
        </div>
        <div class="btn-group">
            <el-tooltip content="画布放大" placement="bottom">
                <div class="btn hbtn" @click="enlargeCanvas()" style="margin-top: 5px;">
                <i class="iconfont icon-fangda"></i>
                </div>
            </el-tooltip>  
            <el-tooltip content="画布缩小" placement="bottom">
                <div class="btn hbtn" @click="narrowCanvas()" style="margin-top: 5px;">
                    <i class="iconfont icon-suoxiao"></i>
                </div>
            </el-tooltip>  
            <el-tooltip content="画布重置" placement="bottom">
                <div class="btn hbtn" @click="resetCanvas()" style="margin-top: 5px;">
                    <i class="iconfont icon-zhongzhi"></i>
                </div>
            </el-tooltip> 
            <el-tooltip content="撤销" placement="bottom">
                <div class="btn hbtn" @click="revoke()" style="margin-top: 5px;">
                    <i class="iconfont icon-chexiao"></i>
                </div>
            </el-tooltip>
            <el-tooltip content="恢复" placement="bottom">
                <div class="btn hbtn" @click="recovery()" style="margin-top: 5px;">
                    <i class="iconfont icon-chexiao" style="display:inline-block;transform:rotateY(180deg);"></i>
                </div>
            </el-tooltip>
            <!-- <el-tooltip content="直线" placement="bottom">
                <div :class=" ['btn',currentArrow === 1?'currentArrow':'']" @click="changeEdgeType('normal')">
                    <i class="iconfont icon-straight"></i>
                </div>
            </el-tooltip> 
            <el-tooltip content="曲线" placement="bottom">
                <div :class=" ['btn',currentArrow === 2?'currentArrow':'']" @click="changeEdgeType('smooth')">
                    <i class="iconfont icon-curve"></i>
                </div>
            </el-tooltip> 
            <el-tooltip content="直角" placement="bottom">
                <div :class=" ['btn',currentArrow === 3?'currentArrow':'']" @click="changeEdgeType()">
                    <i class="iconfont icon-youxianzhuanhuanweilujing"></i>
                </div>
            </el-tooltip>  -->
        </div>
        <div class="btn-group">
            <el-tooltip content="左对齐" placement="bottom">
                <div class="btn hbtn" @click="toAlign('left')" style="margin-top: 5px;">
                    <i class="iconfont icon-zuoduiqi"></i>
                </div>
            </el-tooltip>
            <el-tooltip content="右对齐" placement="bottom">
                <div class="btn hbtn" @click="toAlign('right')" style="margin-top: 5px;">
                    <i class="iconfont icon-youduiqi"></i>
                </div>
            </el-tooltip>
            <el-tooltip content="顶部对齐" placement="bottom">
                <div class="btn hbtn" @click="toAlign('top')" style="margin-top: 5px;">
                    <i class="iconfont icon-dingbuduiqi"></i>
                </div>
            </el-tooltip>
            <el-tooltip content="底部对齐" placement="bottom">
                <div class="btn hbtn" @click="toAlign('bottom')" style="margin-top: 5px;">
                    <i class="iconfont icon-dibuduiqi"></i>
                </div>
            </el-tooltip>
            <el-tooltip content="水平居中" placement="bottom">
                <div class="btn hbtn" @click="toAlign('horizontalCenter')" style="margin-top: 5px;">
                    <i class="iconfont icon-chuizhijuzhong"></i>
                </div>
            </el-tooltip>
            <el-tooltip content="垂直居中" placement="bottom">
                <div class="btn hbtn" @click="toAlign('verticalCenter')" style="margin-top: 5px;">
                    <i class="iconfont icon-juzhong"></i>
                </div>
            </el-tooltip>
        </div>
        <div class="btn-group">
            <el-tooltip content="置于顶层" placement="bottom">
                <div class="btn hbtn" @click="toTopZIndex()" style="margin-top: 5px;">
                    <i class="el-icon-upload2"></i>
                </div>
            </el-tooltip> 
            <el-tooltip content="置于底层" placement="bottom">
                <div class="btn hbtn" @click="toBackZIndex()" style="margin-top: 5px;">
                    <i class="el-icon-upload2" style="transform:rotate(180deg);"></i>
                </div>
            </el-tooltip> 
            <el-tooltip content="删除" placement="bottom">
                <div class="btn hbtn" @click="deleteNode()" style="margin-top: 5px;">
                    <i class="iconfont icon-changyonggoupiaorenshanchu"></i>
                </div>
            </el-tooltip> 
            <!-- <el-tooltip content="导出图片" placement="bottom">
                <div class="btn" @click="saveToPNG()">
                    <i class="iconfont icon-daochu"></i>
                </div>
            </el-tooltip>  -->
        </div>
        <div class="btn-group">
            <el-tooltip content="复制画布" placement="bottom">
                <div class="btn hbtn" @click="copyCanvas()" style="margin-top: 5px;">
                <i class="iconfont icon-fuzhi-L"></i>
                </div>
            </el-tooltip>  
            <el-tooltip content="粘贴画布" placement="bottom">
                <div class="btn hbtn" @click="pasteCanvas()" style="margin-top: 5px;">
                    <i class="iconfont icon-niantie-L"></i>
                </div>
            </el-tooltip>  
        </div>
    </div>
</template>
<script>
import html2canvas from 'html2canvas';
import { exportConfig } from '@/js/Graph/exportConfig';
import { diff } from '@antv/x6/lib/util/array/diff';

export default {
    components: {
      html2canvas
    },
    data() {
        return {
            currentArrow:1,
            connectEdgeType: this.connectEdgeTypes,
        }
    },
    props: {
        selectCell:{
            type: String | Object
        },
        graph:{
            type: String | Object
        },
        connectEdgeTypes: {
            type: Object
        },
        routerName: {
            type: String
        }
    },
    methods: {
        // 放大画布
        enlargeCanvas() {
            this.graph.zoom(0.2)
        },
        // 缩小画布
        narrowCanvas() {
            this.graph.zoom(-0.2)
        },
        // 重置画布
        resetCanvas() {
            if(this.graph.zoom()!=1) {
                let zoom = this.graph.zoom()
                let handle = parseFloat(zoom.toFixed(2))
                let a = parseFloat((handle - 1).toFixed(2))
                this.graph.zoom(-a)
            }
        },
        // 撤销
        revoke() {
            this.graph.history.undo()
        },
        // 恢复
        recovery() {
            this.graph.history.redo()
        },
        // 对齐方式
        toAlign(direction) {
            let cell = this.graph.getSelectedCells()
            let arr = []
            let hMin = null
            let hMax = null
            let vMin = null
            let vMax = null
            if(direction=='left') {
                cell.forEach(v=>arr.push(v.store.data.position.x))
                hMin = Math.min(...arr)
                cell.forEach(v=>v.position(hMin,v.store.data.position.y))
            }
            if(direction=='right') {
                cell.forEach(v=>arr.push(v.store.data.position.x))
                hMax = Math.max(...arr)
                cell.forEach(v=>v.position(hMax,v.store.data.position.y))
            }
            if(direction=='top') {
                cell.forEach(v=>arr.push(v.store.data.position.y))
                vMin = Math.min(...arr)
                cell.forEach(v=>v.position(v.store.data.position.x, vMin))
            }
            if(direction=='bottom') {
                cell.forEach(v=>arr.push(v.store.data.position.y))
                vMax = Math.max(...arr)
                cell.forEach(v=>v.position(v.store.data.position.x, vMax))
            }
            if(direction=='horizontalCenter') {
                cell.forEach(v=>arr.push(v.store.data.position.y))
                let horMin = Math.min(...arr)
                let horMax = Math.max(...arr)
                let horAverageResult = (horMax + horMin) / 2
                cell.forEach(v=>v.position(v.store.data.position.x, horAverageResult))
            }
            if(direction=='verticalCenter') {
                cell.forEach(v=>arr.push(v.store.data.position.x))
                let verMin = Math.min(...arr)
                let verMax = Math.max(...arr)
                let verAverageResult = (verMax + verMin) / 2
                cell.forEach(v=>v.position(verAverageResult, v.store.data.position.y))
            }
        },
        // 置顶
        toTopZIndex(){
            this.selectCell.toFront()
        },
        // 置底
        toBackZIndex(){
            this.selectCell.toBack()
        },
        // 删除节点
        deleteNode(){
            const cell = this.graph.getSelectedCells()
            this.graph.removeCells(cell)
            this.type = 'grid'
        },
        // 复制画布
        copyCanvas() {
            let graphToJSON = this.graph.toJSON()
            sessionStorage.setItem('copyAllCanvasCellsData', JSON.stringify(graphToJSON))
        },
        // 粘贴画布
        pasteCanvas() {
            if(sessionStorage.getItem('copyAllCanvasCellsData')) {
                let cells = sessionStorage.getItem('copyAllCanvasCellsData')
                let cellsParse = JSON.parse(cells)
                this.graph.fromJSON(cellsParse.cells)
            }
        },
        // 改变边形状
        changeEdgeType(e){
            if(e === 'normal'){
                this.connectEdgeType = {
                    connector: 'normal',
                    router: {name: ''}
                }
                this.currentArrow = 1
            }else if (e === 'smooth'){
                this.connectEdgeType = {
                    connector: 'smooth',
                    router: {name: ''}
                }
                this.currentArrow = 2
            }else{
                this.connectEdgeType = {
                    connector: 'normal',
                    router: {name: 'manhattan'}
                }
                this.currentArrow = 3
            }
        },
        // 图片地址转base64编码
        getBase64Image(img) {
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, img.width, img.height);
            var ext = img.src.substring(img.src.lastIndexOf(".")+1).toLowerCase();
            var dataURL = canvas.toDataURL('image/'+ext);
            return dataURL
        },
        // 处理图片对象onload加载为同步
        imageOnload(v) {
            return new Promise((resolve,reject)=>{
                let image = new Image();
                image.setAttribute('crossOrigin','anonymous')
                image.crossOrigin = '';
                image.src = v.getAttribute('xlink:href');
                image.onload = () => {
                    let base64 = this.getBase64Image(image)
                    v.setAttribute('xlink:href',base64)
                    resolve()
                }
            })
        },
        // 画布中所有图片转base64
        async imageToBase64(){
            return new Promise(async (resolve,reject)=>{
                let container =  document.getElementById('containerChart')
                let imgElem = container.querySelectorAll('image')
                for(let i=0;i<imgElem.length;i++) {
                    await this.imageOnload(imgElem[i])
                }
                resolve()
            })
        },
        // 保存画布内容为png图片
        saveToPNG() {
            return new Promise((resolve,reject)=>{
                let ref = document.getElementById('containerChart')
                this.graph.hideGrid()
                html2canvas(ref,{
                    useCORS: true,
                    backgroundColor: '#fff',//#f2fee7
                }).then(canvas => {
                    let dataURL = canvas.toDataURL('image/png');
                    console.log(dataURL)
                    sessionStorage.setItem('canvasURL', dataURL)
                    this.graph.showGrid()
                    resolve()
                })
            })
        },
        // 导出数据
        async exportToJSON() {
            let allNodes = this.graph.getNodes()
            if(allNodes.length==0) {
                return this.$message.error('请绘制组态图')
            }
            this.$emit('onLoading',true)
            // 组态截图
            await this.imageToBase64()
            await this.saveToPNG()
            // 画布数据序列化
            exportConfig(this.graph)
            this.$emit('onLoading',false)
            this.$router.push({ name: this.routerName })
        },
    }
}
</script>