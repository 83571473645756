<template>
    <div>
        <div class="drawer_title">线条设置</div>
        <div class="drawer_wrap">
            <el-form label-width="80px" label-position="top">
                <el-form-item label="线条文本" label-width="100px">
                    <el-input size="small" v-model="drawerEdge.EdgeText" @change="changeEdgeText"></el-input>
                </el-form-item>
                <el-form-item label="线条宽度" label-width="110px">
                    <el-slider v-model="drawerEdge.edgeWidth" :min="1" :max="10" @change="changeEdgeWidth"></el-slider>
                </el-form-item>
                <el-form-item label="线条颜色" label-width="100px">
                    <el-color-picker v-model="drawerEdge.edgeColor" @change="changeEdgeColor"/>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: {
        selectCell:{
            type: String | Object
        },
        drawerEdge: {
            type: Object
        }
    },
    methods: {
        // 边设置
        changeEdgeText(){
            console.log(this.drawerEdge.EdgeText);
            this.selectCell.setLabels( 
                [{attrs:{label:{text:this.drawerEdge.EdgeText}}}]
            )
        },
        changeEdgeWidth(val){
            this.drawerEdge.edgeWidth = val
            this.selectCell.attr('line/strokeWidth', this.drawerEdge.edgeWidth)
        },
        changeEdgeColor(val){
            this.drawerEdge.stroke = val
            this.selectCell.attr('line/stroke', this.drawerEdge.stroke)
        },
    }
}
</script>
<style lang="less" scoped>
    @import '../../../../css/iotConfigManage/rightDrawer.less';
</style>