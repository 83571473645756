/**
 * 导入数据-文本变化
 */
 export const importTextChange = (item,textChange) => {
    item.textChange.dataParameters.forEach(textc => {
        let boolData = {}
        let realData = {}
        if (textc.dataType == 'Bool') {
            realData = {
                formulaType: 'empty',
                tag: '',
                unit: '',
                realTagData: [
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    }
                ]
            }
            let boolTagData = []
            if (textc.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
                boolData = {
                    formulaType: textc.boolData.formulaType,
                    tag: textc.boolData.tag,
                    tagValue: textc.boolData.tagValue,
                    tagValueDescribe: textc.boolData.tagValueDescribe,
                    boolTagData: [
                        {
                            tag: '',
                            tagDrawer: false,
                            tagRadio: '',
                        },
                        {
                            tag: '',
                            tagDrawer: false,
                            tagRadio: '',
                        }
                    ]
                }
            }
            if (textc.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
                let arr = textc.boolData.tag.split('&')
                arr.forEach(arrv => {
                    boolTagData.push({
                        tag: arrv,
                        tagDrawer: false,
                        tagRadio: '',
                    })
                })
                boolData = {
                    formulaType: textc.boolData.formulaType,
                    tag: '',
                    tagValue: textc.boolData.tagValue,
                    tagValueDescribe: textc.boolData.tagValueDescribe,
                    boolTagData: boolTagData
                }
            }
            if (textc.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
                let arr = textc.boolData.tag.split('||')
                arr.forEach(arrv => {
                    boolTagData.push({
                        tag: arrv,
                        tagDrawer: false,
                        tagRadio: '',
                    })
                })
                boolData = {
                    formulaType: textc.boolData.formulaType,
                    tag: '',
                    tagValue: textc.boolData.tagValue,
                    tagValueDescribe: textc.boolData.tagValueDescribe,
                    boolTagData: boolTagData
                }
            }
            if (textc.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
                boolData = {
                    formulaType: textc.boolData.formulaType,
                    tag: textc.boolData.tag,
                    tagValue: textc.boolData.tagValue,
                    tagValueDescribe: textc.boolData.tagValueDescribe,
                    boolTagData: [
                        {
                            tag: '',
                            tagDrawer: false,
                            tagRadio: '',
                        },
                        {
                            tag: '',
                            tagDrawer: false,
                            tagRadio: '',
                        }
                    ]
                }
            }
        }
        if (textc.dataType == 'Real') {
            boolData = {
                formulaType: 'empty',
                tag: '',
                tagValue: '',
                tagValueDescribe: '',
                boolTagData: [
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    }
                ]
            }
            let realTagData = []
            if (textc.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
                realData = {
                    formulaType: textc.realData.formulaType,
                    tag: textc.realData.tag,
                    unit: textc.realData.unit,
                    realTagData: [
                        {
                            tag: '',
                            tagDrawer: false,
                            tagRadio: '',
                        },
                        {
                            tag: '',
                            tagDrawer: false,
                            tagRadio: '',
                        }
                    ]
                }
            }
            if (textc.realData.formulaType == '+') {//Real类型 运算符为 '+'
                let arr = textc.realData.tag.split('+')
                arr.forEach(arrv => {
                    realTagData.push({
                        tag: arrv,
                        tagDrawer: false,
                        tagRadio: '',
                    })
                })
                realData = {
                    formulaType: textc.realData.formulaType,
                    tag: '',
                    unit: textc.realData.unit,
                    realTagData: realTagData
                }
            }
            if (textc.realData.formulaType == '-') {//Real类型 运算符为 '-'
                let arr = textc.realData.tag.split('-')
                arr.forEach(arrv => {
                    realTagData.push({
                        tag: arrv,
                        tagDrawer: false,
                        tagRadio: '',
                    })
                })
                realData = {
                    formulaType: textc.realData.formulaType,
                    tag: '',
                    unit: textc.realData.unit,
                    realTagData: realTagData
                }
            }
        }
        textChange.push({
            dataType: textc.dataType,
            boolData: boolData,
            realData: realData,
            textColor: textc.textColor,
            display: textc.display,
            tagDrawer: false,
            tagRadio: '',
        })
    })
    return textChange
}