<template>
    <div>
        <el-form-item label="动画类型">
            <el-select size="small" v-model="drawerNode.animationType" placeholder="请选择动画类型" @change="changeAnimationType">
                <el-option
                    v-for="item in animationTypes"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"></el-option>
            </el-select>
        </el-form-item>
        <div class="" v-show="drawerNode.animationType">
            <div class="tag-con-head">
                <div class="tag-label">动效配置</div>
                <div v-if="drawerNode.customAnimations">
                    <el-button type="text" size="mini" @click="aniAddTagItem">添加项</el-button>
                    <el-button :style="drawerNode.customAnimations.length==1?'color:#ccc;':'color:red;'" type="text" size="mini" @click="aniDelTagItem" :disabled="drawerNode.customAnimations.length==1">删除项</el-button>
                </div>
            </div>
            <div class="tag-content" v-for="(item, index) in drawerNode.customAnimations" :key="index">
                <div v-show="drawerNode.animationType=='animation'">
                    <el-form-item label="动画效果">
                        <el-select size="mini" v-model="item.animation" placeholder="请选择动画效果" @change="changeAnimationEffects">
                            <el-option
                                v-for="item in animationEffects"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div v-if="item.animation">
                    <!-- 动画效果为'无动画' -->
                    <div v-if="item.animation=='Empty'">
                        <empty :selectCell="selectCell" :drawerNode="drawerNode" :tagTableData="tagTableData" :item="item"></empty>
                    </div>
                    <!-- 动画效果为'流水动画' -->
                    <div v-if="item.animation=='PipelineFlow'">
                        <pipeline-flow :selectCell="selectCell" :drawerNode="drawerNode" :tagTableData="tagTableData" :item="item"></pipeline-flow>
                    </div>
                    <!-- 动画效果为'液位' -->
                    <div v-if="item.animation=='waterLevel'">
                        <waterLevel :selectCell="selectCell" :drawerNode="drawerNode" :tagTableData="tagTableData" :item="item"></waterLevel>
                    </div>
                    <!-- 动画效果为'文本变化' -->
                    <div v-if="item.animation=='textChange'">
                        <text-change :selectCell="selectCell" :drawerNode="drawerNode" :tagTableData="tagTableData" :item="item"></text-change>
                    </div>
                    <!-- 动画效果为'背景变化' -->
                    <div v-if="item.animation=='background'">
                        <background :selectCell="selectCell" :drawerNode="drawerNode" :tagTableData="tagTableData" :item="item" :resourceData="resourceData"></background>
                    </div>
                    <!-- 动画效果为'旋转' -->
                    <div v-if="item.animation=='rotate'">
                        <img-rotate :selectCell="selectCell" :drawerNode="drawerNode" :tagTableData="tagTableData" :item="item"></img-rotate>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Empty from './dynamicEffects/Empty';
import PipelineFlow from './dynamicEffects/PipelineFlow';
import WaterLevel from './dynamicEffects/WaterLevel';
import TextChange from './dynamicEffects/TextChange';
import Background from './dynamicEffects/Background';
import ImgRotate from './dynamicEffects/ImgRotate';
export default {
    components: {
        Empty,
        PipelineFlow,
        WaterLevel,
        TextChange,
        Background,
        ImgRotate,
    },
    data() {
        return {
            animationTypes: [//动态图片行为类别 -> 动画类型
                { code: 'animation', name: '动效' },
                // { code: 'picture_animation', name: '图片动画' },
            ],
            animationEffects: [//动态图片行为类别 -> 动画效果
                { code: 'Empty', name: '无效果' },
                { code: 'PipelineFlow', name: '管道流水' },
                { code: 'waterLevel', name: '液位' },
                { code: 'textChange', name: '文本变化' },
                { code: 'background', name: '背景变化' },
                { code: 'rotate', name: '旋转' },
            ],
        }
    },
    props: {
        selectCell:{
            type: String | Object
        },
        drawerNode: {
            type: Object
        },
        tagTableData: {
            type: Array
        },
        resourceData: {
            type: Array
        }
    },
    methods: {
        // 动画类型
        changeAnimationType(val) {
            console.log(val)
            this.selectCell.setData({
                graphical: {
                    animationType: val,
                }
            })
        },
        // 新增Tag项
        aniAddTagItem() {
            this.drawerNode.customAnimations.push({
                animation: '',
                withoutData: null,
                startParameter: {
                    dataType: '',
                    boolData: {
                        formulaType: 'empty',
                        tag: '',
                        tagValue: '',
                        boolTagData: [
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            },
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            }
                        ]
                    },
                    realData: {
                        formulaType: 'empty',
                        tag: '',
                        unit: '',
                        realTagData: [
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            },
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            }
                        ]
                    },
                    tagDrawer: false,
                    tagRadio: '',
                },
                stopParameter: {
                    display: '',
                    dataType: '',
                    boolData: {
                        formulaType: 'empty',
                        tag: '',
                        tagValue: '',
                        boolTagData: [
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            },
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            }
                        ]
                    },
                    realData: {
                        formulaType: 'empty',
                        tag: '',
                        unit: '',
                        realTagData: [
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            },
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            }
                        ]
                    },
                    tagDrawer: false,
                    tagRadio: '',
                },
                EmptyAnimation: {
                    dataType: '',
                    boolData: {
                        formulaType: 'empty',
                        tag: '',
                        tagValue: '',
                        boolTagData: [
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            },
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            }
                        ]
                    },
                    realData: {
                        formulaType: 'empty',
                        tag: '',
                        unit: '',
                        realTagData: [
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            },
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            }
                        ]
                    },
                    tagDrawer: false,
                    tagRadio: '',
                },
                textChange: [
                    {
                        dataType: '',
                        boolData: {
                            formulaType: 'empty',
                            tag: '',
                            tagValue: '',
                            tagValueDescribe: '',
                            boolTagData: [
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }
                            ]
                        },
                        realData: {
                            formulaType: 'empty',
                            tag: '',
                            unit: '',
                            realTagData: [
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }
                            ]
                        },
                        textColor: '',
                        display: '',
                        tagDrawer: false,
                        tagRadio: '',  
                    }
                ],
                background: [
                    {
                        dataType: '',
                        boolData: {
                            formulaType: 'empty',
                            tag: '',
                            tagValue: '',
                            tagValueDescribe: '',
                            boolTagData: [
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }
                            ]
                        },
                        realData: {
                            formulaType: 'empty',
                            tag: '',
                            unit: '',
                            realTagData: [
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }
                            ]
                        },
                        backgroundType: '',
                        backgroundFilePath: '',
                        backgroundColor: '',
                        tagDrawer: false,
                        tagRadio: '',  
                        bgImgDrawer: false,
                        bgImgRadio: '',
                    }
                ],
                imgRotate: {
                    startParameter: {
                        dataType: '',
                        boolData: {
                            formulaType: 'empty',
                            tag: '',
                            tagValue: '',
                            boolTagData: [
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }
                            ]
                        },
                        realData: {
                            formulaType: 'empty',
                            tag: '',
                            unit: '',
                            realTagData: [
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }
                            ]
                        },
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    stopParameter: {
                        display: '',
                        dataType: '',
                        boolData: {
                            formulaType: 'empty',
                            tag: '',
                            tagValue: '',
                            boolTagData: [
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }
                            ]
                        },
                        realData: {
                            formulaType: 'empty',
                            tag: '',
                            unit: '',
                            realTagData: [
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                },
                                {
                                    tag: '',
                                    tagDrawer: false,
                                    tagRadio: '',
                                }
                            ]
                        },
                        tagDrawer: false,
                        tagRadio: '',
                    },
                },
                animationRate: {
                    rateType: null,
                    manualRate: null,
                    relativeTag: null,
                    relativeTagValue: null,
                },
                tagName: '',
                tagValue: '',
                waterLevel: {
                    max: '',
                    defaultValue: ''
                },
                tagDrawer: false,
                tagRadio: '',
            })
        },
        // 删除Tag项
        aniDelTagItem() {
            this.drawerNode.customAnimations.pop()
        },
        // 监听循环选择动画效果
        changeAnimationEffects(val) {
            console.log(val)
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
            console.log(this.selectCell)
        },
        // tag对应图片上传
        uploadChange(file, fileList, val) {
            val.allDynamicFileList = fileList;
            val.hideDynamicUpload = fileList.length >= val.limitCount
        },
        // tag对应图片移除
        handleRemove(file, fileList, val) {
            function checkAdult(item) {
                return item.uid != file.uid;
            }
            val.allDynamicFileList = val.allDynamicFileList.filter(checkAdult);
            if(fileList) {
                val.hideDynamicUpload = fileList.length >= val.limitCount
            }else {
                val.hideDynamicUpload = false
            }
        }
    }
}
</script>
<style lang="less" scoped>
    @import '../../../../../css/iotConfigManage/rightDrawer.less';
</style>