<template>
    <div>
        <el-form-item label="最大值" style="margin-bottom: 10px;">
            <el-input size="small" v-model="drawerNode.editTextMax" placeholder="请输入最大值" @change="changeTextMax"></el-input>
        </el-form-item>
        <el-form-item label="最小值" style="margin-bottom: 10px;">
            <el-input size="small" v-model="drawerNode.editTextMin" placeholder="请输入最小值" @change="changeTextMin"></el-input>
        </el-form-item>
        <el-form-item label="小数点位数" label-width="90px" style="margin-bottom: 10px;">
            <el-input size="small" v-model="drawerNode.editTextPointBehindNum" placeholder="请输入小数点位数" @change="changeTextPointBehindNum"></el-input>
        </el-form-item>
        <el-form-item label="单位" style="margin-bottom: 10px;">
            <el-input size="small" v-model="drawerNode.editTextUnit" placeholder="请输入单位" @change="changeTextUnit"></el-input>
        </el-form-item>
        <div style="display:flex;">
            <el-button v-if="!drawerNode.editTextTag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag()">选择Tag</el-button>
            <span v-else class="close-lube-btn">
                {{drawerNode.editTextTag}}
                <span class="el-icon-error close-lube" @click="clearItemTag()"></span>
            </span>
        </div>
        <el-form-item label="Tag描述" style="margin-bottom: 10px;">
            <el-input size="small" v-model="drawerNode.editTextTagDescribe" placeholder="请输入Tag描述" @change="changeTextTagDescribe"></el-input>
        </el-form-item>
        <!-- 侧滑选择Tag -->
        <el-drawer
            title="选择Tag"
            :with-header="false"
            :visible.sync="editDrawer"
            direction="rtl"
            size="800px">
            <div class="drawer-title">选择Tag</div>
            <div class="drawer-content table-tree">
            <el-table :data="tagTableData" height="700">
                <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                    <el-radio-group v-model="editRadio" @change="checkTagChange(scope.row)">
                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                    </el-radio-group>
                </template>
                </el-table-column>
                <el-table-column prop="number" label="序号" width="50"></el-table-column>
                <el-table-column prop="dataType" label="数据类型"></el-table-column>
                <el-table-column prop="pointName" label="点名"></el-table-column>
                <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                <el-table-column prop="cycle" label="采集周期"></el-table-column>
            </el-table>
            </div>
            <div class="drawer-bottom">
            <el-button size="small" type="primary" @click="editDrawer=false">取消</el-button>
            <el-button
                size="small"
                type="primary"
                @click="editDrawerOK()"
                class="drawer-bottom-ok">确 定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            editDrawer: false,
            editRadio: '',
            tagSelectObj: {},
        }
    },
    props: {
        selectCell:{
            type: String | Object
        },
        drawerNode: {
            type: Object
        },
        tagTableData: {
            type: Array
        }
    },
    methods: {
        changeTextMax(e) {
            this.selectCell.setData({
                graphical: {
                    max: this.drawerNode.editTextMax,
                }
            })
        },
        changeTextMin(e) {
            this.selectCell.setData({
                graphical: {
                    min: this.drawerNode.editTextMin,
                }
            })
        },
        changeTextPointBehindNum(e) {
            this.selectCell.setData({
                graphical: {
                    pointBehindNum: this.drawerNode.editTextPointBehindNum,
                }
            })
        },
        changeTextUnit(e) {
            this.selectCell.setData({
                graphical: {
                    unit: this.drawerNode.editTextUnit,
                }
            })
        },
        changeTextTagDescribe(e) {
            this.selectCell.setData({
                graphical: {
                    tagDescribe: this.drawerNode.editTextTagDescribe,
                }
            })
        },
        // 勾选Tag
        checkTagChange(row) {
            this.tagSelectObj = row
        },
        selectItemTag() {
            this.editDrawer = true
        },
        clearItemTag() {
            this.drawerNode.editTextTag = null
            this.drawerNode.editTextTagDescribe = null
        },
        editDrawerOK() {
            this.editDrawer = false
            this.drawerNode.editTextTag = this.tagSelectObj.pointName
            this.drawerNode.editTextTagDescribe = this.tagSelectObj.describe
            this.selectCell.setData({
                graphical: {
                    tag: this.drawerNode.editTextTag,
                    tagDescribe: this.drawerNode.editTextTagDescribe,
                }
            })
            console.log(this.selectCell)
        }
    }
}
</script>
<style lang="less" scoped>
    @import '../../../../../css/iotConfigManage/rightDrawer.less';
</style>
