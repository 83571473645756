<template>
    <div>
        <el-tabs v-model="canvasConfiguration" type="card">
            <el-tab-pane label="画布配置" name="first">
                <div class="drawer_wrap">
                    <el-form label-width="100px" label-position="left">
                        <div class="nodestyle-text">
                            <div class="nodestyle-text-title">画布</div>
                            <div class="nodestyle-text-content">
                                <el-form-item label="是否显示网格" style="padding: 5px 0;">
                                    <el-switch v-model="showGrid" @change="changeShowGrid" style="margin-top: 10px;margin-left: 5px;"/>
                                </el-form-item>
                                <div v-show="showGrid">
                                    <el-form-item label="网格类型" style="padding: 5px 0;">
                                        <el-radio-group v-model="grid.type" @change="changeGridType" style="margin-top: 13px;margin-left: 5px;">
                                            <el-radio v-for="item in gridTypeList" :label="item.value" :key="item.value">
                                                <span>{{item.label}}</span>
                                            </el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="网格大小" style="padding: 5px 0;">
                                        <el-slider v-model="grid.size" :min="0" :max="30" @change="changeGrid" style="margin-left: 6px;"></el-slider>
                                    </el-form-item>
                                    <el-form-item label="网格颜色" style="padding: 5px 0;">
                                        <el-color-picker :predefine="predefineColors" size="small" v-model="grid.args.color" show-alpha @change="changeGrid" style="margin-top: 5px;"/>
                                    </el-form-item>
                                    <el-form-item label="网格线宽度" style="padding: 5px 0;">
                                        <el-slider v-model="grid.args.thickness" :min="0" :max="20" @change="changeGrid" style="margin-left: 4px;"></el-slider>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    data() {
        return {
            canvasConfiguration: 'first',
            gridTypeList:[
                {
                    label:'网状',
                    value:'mesh'
                },
                {
                    label:'点状',
                    value:'dot'
                }
            ],
            showGrid:true,
            predefineColors: [
                '#ff0000',//50%
                '#ff8c00',
                '#ffd700',
                '#0ba618',
                '#1e90ff',
                '#6215c7',
                '#c71585',
                '#ff006a',
                '#ffffff',
                '#000000',

                '#ffe5e5',//95%
                '#fff3e5',
                '#fffbe5',
                '#e7fde9',
                '#e5f2ff',
                '#f1e8fd',
                '#fde8f5',
                '#ffe5f0',
                '#f2f2f2',
                '#a6a6a6',

                '#ffb3b3',//85%
                '#ffddb3',
                '#fff3b3',
                '#b7fabd',
                '#b3d9ff',
                '#d5baf8',
                '#f8bae1',
                '#ffb3d2',
                '#e6e6e6',
                '#8c8c8c',

                '#ff8080',//75%
                '#ffc680',
                '#ffeb80',
                '#87f791',
                '#80c0ff',
                '#b88cf3',
                '#f38ccd',
                '#ff80b5',
                '#d9d9d9',
                '#737373',

                '#ff4d4d',//65%
                '#ffaf4d',
                '#ffe34d',
                '#58f465',
                '#4da7ff',
                '#9c5eee',
                '#ee5eb8',
                '#ff4d97',
                '#cccccc',
                '#595959',

                '#ff1a1a',//55%
                '#ff981a',
                '#ffdb1a',
                '#28f139',
                '#0081ff',
                '#802fe9',
                '#e92fa4',
                '#ff1a79',
                '#bfbfbf',
                '#404040',
            ],    
        }
    },
    props: {
        graph:{
            type: String | Object
        },
        grid:{
            type: Object
        }
    },
    methods: {
        // 网格显示
        changeShowGrid() {
            if(this.showGrid) {
                this.graph.showGrid()
            }else {
                this.graph.hideGrid()
            }
        },
        // 网格设置
        changeGrid(){
            this.graph.drawGrid({
                ...this.grid
            })
        },
        // 网格类型
        changeGridType(e){
            this.grid.type = e
            this.changeGrid()
        },
    }
}
</script>

<style lang="less" scoped>
    @import '../../../../css/iotConfigManage/rightDrawer.less';
</style>