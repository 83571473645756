<template>
    <div>
        <div v-if="drawerNode.withData==0">
            <el-form-item label="速率类型">
                <el-select size="mini" v-model="item.animationRate.rateType" placeholder="请选择速率类型" @change="changeAnimationDataType">
                    <el-option
                        v-for="item in rateTypes"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"></el-option>
                </el-select>
            </el-form-item>
            <div v-if="item.animationRate.rateType=='manual'">
                <el-form-item label="动画速率">
                    <el-select size="mini" v-model="item.animationRate.manualRate" placeholder="请选择动画速率" @change="changeAnimationDataType">
                        <el-option
                            v-for="item in manualRates"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div v-if="item.animationRate.rateType=='auto'">
                <div>
                    <div style="font-size:14px;color:#606266;margin: 8px 0;">选择Tag</div>
                    <el-button v-if="!item.animationRate.relativeTag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(item)">选择Tag</el-button>
                    <span v-else class="close-lube-btn">
                        {{item.animationRate.relativeTag}}
                        <span class="el-icon-error close-lube" @click="clearItemRelativeTag(item)"></span>
                    </span>
                </div>
                <el-form-item label="基准值">
                    <el-input size="mini" v-model="item.animationRate.relativeTagValue" placeholder="请输入基准值" @change="changeItemTag"></el-input>
                </el-form-item>
            </div>
            <el-drawer
                title="选择Tag"
                :with-header="false"
                :visible.sync="item.tagDrawer"
                direction="rtl"
                size="800px">
                <div class="drawer-title">选择Tag</div>
                <div class="drawer-content table-tree">
                <el-table :data="tagTableData" height="700">
                    <el-table-column label="勾选" width="70">
                    <template slot-scope="scope">
                        <el-radio-group v-model="item.tagRadio" @change="checkTagChange(scope.row)">
                        <el-radio :label="scope.row.id">{{''}}</el-radio>
                        </el-radio-group>
                    </template>
                    </el-table-column>
                    <el-table-column prop="number" label="序号" width="50"></el-table-column>
                    <el-table-column prop="dataType" label="数据类型"></el-table-column>
                    <el-table-column prop="pointName" label="点名"></el-table-column>
                    <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                    <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                    <el-table-column prop="cycle" label="采集周期"></el-table-column>
                </el-table>
                </div>
                <div class="drawer-bottom">
                <el-button size="small" type="primary" @click="item.tagDrawer=false">取消</el-button>
                <el-button
                    size="small"
                    type="primary"
                    @click="tagItemDrawerOK(item)"
                    class="drawer-bottom-ok">确 定</el-button>
                </div>
            </el-drawer>
            <div class="animation-title animation-start-title">开始条件</div>
            <!-- 开始条件 -->
            <div>
                <el-form-item label="数据类型">
                    <el-select size="mini" v-model="item.startParameter.dataType" placeholder="请选择数据类型" @change="changeAnimationDataType">
                        <el-option
                            v-for="item in dataType"
                            :key="item"
                            :label="item"
                            :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <!-- Bool类型 -->
                <div v-show="item.startParameter.dataType=='Bool'">
                    <el-form-item label="运算符">
                        <el-radio-group v-model="item.startParameter.boolData.formulaType" @change="changePipeline">
                            <el-radio :label="'empty'">无</el-radio>
                            <el-radio :label="'&'">&</el-radio>
                            <el-radio :label="'||'">||</el-radio>
                            <el-radio :label="'!'">!</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!-- 运算符为 '无'和'!' -->
                    <div class="con-middle-tag-head" style="margin: 10px 0;" v-show="item.startParameter.boolData.formulaType=='empty'||item.startParameter.boolData.formulaType=='!'">
                        <div>
                            <el-button v-if="!item.startParameter.boolData.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(item.startParameter)">选择Tag</el-button>
                            <span v-else class="close-lube-btn">
                                {{item.startParameter.boolData.tag}}
                                <span class="el-icon-error close-lube" @click="clearItemStartParameterTag(item)"></span>
                            </span>
                        </div>
                        <div>
                            <el-input size="mini" v-model="item.startParameter.boolData.tagValue" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                        </div>
                    </div>
                    <!-- 运算符为 '&' -->
                    <div v-show="item.startParameter.boolData.formulaType=='&'">
                        <div class="flex-change">
                            <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                            <div v-if="item.startParameter.boolData.boolTagData">
                                <el-button type="text" @click="btAddTag(item.startParameter.boolData.boolTagData)">新增</el-button>
                                <el-button :style="item.startParameter.boolData.boolTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.startParameter.boolData.boolTagData.length==2" @click="btDelTag(item.startParameter.boolData.boolTagData)">删除</el-button>
                            </div>
                        </div>
                        <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                            <div style="width:50%;margin-bottom:10px;" v-for="(btItem, btIndex) in item.startParameter.boolData.boolTagData" :key="btIndex">
                                <div style="display:flex;">
                                    <el-button v-if="!btItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(btItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{btItem.tag}}
                                        <span class="el-icon-error close-lube" @click="clearBtItemTag(btItem)"></span>
                                    </span>
                                    <div v-show="item.startParameter.boolData.boolTagData.length-1!=btIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">&</div>
                                </div>
                                        
                                <!-- 运算符为 '&' 的侧滑选择Tag -->
                                <el-drawer
                                    title="选择Tag"
                                    :with-header="false"
                                    :visible.sync="btItem.tagDrawer"
                                    direction="rtl"
                                    size="800px">
                                    <div class="drawer-title">选择Tag</div>
                                    <div class="drawer-content table-tree">
                                    <el-table :data="tagTableData" height="700">
                                        <el-table-column label="勾选" width="70">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="btItem.tagRadio" @change="checkTagChange(scope.row)">
                                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                                            </el-radio-group>
                                        </template>
                                        </el-table-column>
                                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                        <el-table-column prop="pointName" label="点名"></el-table-column>
                                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                    </el-table>
                                    </div>
                                    <div class="drawer-bottom">
                                    <el-button size="small" type="primary" @click="btItem.tagDrawer=false">取消</el-button>
                                    <el-button
                                        size="small"
                                        type="primary"
                                        @click="tagBtItemDrawerOK(btItem)"
                                        class="drawer-bottom-ok">确 定</el-button>
                                    </div>
                                </el-drawer>
                            </div>
                        </div>
                        <div>
                            <el-input size="mini" v-model="item.startParameter.boolData.tagValue" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                        </div>
                    </div>
                    <!-- 运算符为 '||' -->
                    <div v-show="item.startParameter.boolData.formulaType=='||'">
                        <div style="display:flex;justify-content:space-between;align-items:center;">
                            <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                            <div v-if="item.startParameter.boolData.boolTagData">
                                <el-button type="text" @click="btAddTag(item.startParameter.boolData.boolTagData)">新增</el-button>
                                <el-button :style="item.startParameter.boolData.boolTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.startParameter.boolData.boolTagData.length==2" @click="btDelTag(item.startParameter.boolData.boolTagData)">删除</el-button>
                            </div>
                        </div>
                        <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                            <div style="width:50%;margin-bottom:10px;" v-for="(btItem, btIndex) in item.startParameter.boolData.boolTagData" :key="btIndex">
                                <div style="display:flex;">
                                    <el-button v-if="!btItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(btItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{btItem.tag}}
                                        <span class="el-icon-error close-lube" @click="clearBtItemTag(btItem)"></span>
                                    </span>
                                    <div v-show="item.startParameter.boolData.boolTagData.length-1!=btIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">||</div>
                                </div>
                                
                                <!-- 运算符为 '||' 的侧滑选择Tag -->
                                <el-drawer
                                    title="选择Tag"
                                    :with-header="false"
                                    :visible.sync="btItem.tagDrawer"
                                    direction="rtl"
                                    size="800px">
                                    <div class="drawer-title">选择Tag</div>
                                    <div class="drawer-content table-tree">
                                    <el-table :data="tagTableData" height="700">
                                        <el-table-column label="勾选" width="70">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="btItem.tagRadio" @change="checkTagChange(scope.row)">
                                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                                            </el-radio-group>
                                        </template>
                                        </el-table-column>
                                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                        <el-table-column prop="pointName" label="点名"></el-table-column>
                                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                    </el-table>
                                    </div>
                                    <div class="drawer-bottom">
                                    <el-button size="small" type="primary" @click="btItem.tagDrawer=false">取消</el-button>
                                    <el-button
                                        size="small"
                                        type="primary"
                                        @click="tagBtItemDrawerOK(btItem)"
                                        class="drawer-bottom-ok">确 定</el-button>
                                    </div>
                                </el-drawer>
                            </div>
                        </div>
                        <div>
                            <el-input size="mini" v-model="item.startParameter.boolData.tagValue" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                        </div>
                    </div>
                </div>
                <!-- Real类型 -->
                <div v-show="item.startParameter.dataType=='Real'">
                    <el-form-item label="运算符">
                        <el-radio-group v-model="item.startParameter.realData.formulaType" @change="changePipeline">
                            <el-radio :label="'empty'">无</el-radio>
                            <el-radio :label="'+'">+</el-radio>
                            <el-radio :label="'-'">-</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!-- 运算符为 '无' -->
                    <div class="con-middle-tag-head" style="margin: 10px 0;" v-show="item.startParameter.realData.formulaType=='empty'">
                        <div>
                            <el-button v-if="!item.startParameter.realData.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(item.startParameter)">选择Tag</el-button>
                            <span v-else class="close-lube-btn">
                                {{item.startParameter.realData.tag}}
                                <span class="el-icon-error close-lube" @click="clearItemTag(item)"></span>
                            </span>
                        </div>
                        <div>
                            <el-input size="mini" v-model="item.startParameter.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                        </div>
                    </div>
                    <!-- 运算符为 '+' -->
                    <div v-show="item.startParameter.realData.formulaType=='+'">
                        <div style="display:flex;justify-content:space-between;align-items:center;">
                            <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                            <div v-if="item.startParameter.realData.realTagData">
                                <el-button type="text" @click="rtAddTag(item.startParameter.realData.realTagData)">新增</el-button>
                                <el-button :style="item.startParameter.realData.realTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.startParameter.realData.realTagData.length==2" @click="rtDelTag(item.startParameter.realData.realTagData)">删除</el-button>
                            </div>
                        </div>
                        <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                            <div style="width:50%;margin-bottom:10px;" v-for="(rtItem, rtIndex) in item.startParameter.realData.realTagData" :key="rtIndex">
                                <div style="display:flex;">
                                    <el-button v-if="!rtItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(rtItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{rtItem.tag}}
                                        <span class="el-icon-error close-lube" @click="clearRtItemTag(rtItem)"></span>
                                    </span>
                                    <div v-show="item.startParameter.realData.realTagData.length-1!=rtIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">+</div>
                                </div>
                                
                                <!-- 运算符为 '+' 的侧滑选择Tag -->
                                <el-drawer
                                    title="选择Tag"
                                    :with-header="false"
                                    :visible.sync="rtItem.tagDrawer"
                                    direction="rtl"
                                    size="800px">
                                    <div class="drawer-title">选择Tag</div>
                                    <div class="drawer-content table-tree">
                                    <el-table :data="tagTableData" height="700">
                                        <el-table-column label="勾选" width="70">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="rtItem.tagRadio" @change="checkTagChange(scope.row)">
                                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                                            </el-radio-group>
                                        </template>
                                        </el-table-column>
                                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                        <el-table-column prop="pointName" label="点名"></el-table-column>
                                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                    </el-table>
                                    </div>
                                    <div class="drawer-bottom">
                                    <el-button size="small" type="primary" @click="rtItem.tagDrawer=false">取消</el-button>
                                    <el-button
                                        size="small"
                                        type="primary"
                                        @click="tagRtItemDrawerOK(rtItem)"
                                        class="drawer-bottom-ok">确 定</el-button>
                                    </div>
                                </el-drawer>
                            </div>
                        </div>
                        <div>
                        <el-input size="mini" v-model="item.startParameter.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                        </div>
                    </div>
                    <!-- 运算符为 '-' -->
                    <div v-show="item.startParameter.realData.formulaType=='-'">
                        <div style="display:flex;justify-content:space-between;align-items:center;">
                            <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                            <div v-if="item.startParameter.realData.realTagData">
                                <el-button type="text" @click="rtAddTag(item.startParameter.realData.realTagData)">新增</el-button>
                                <el-button :style="item.startParameter.realData.realTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.startParameter.realData.realTagData.length==2" @click="rtDelTag(item.startParameter.realData.realTagData)">删除</el-button>
                            </div>
                        </div>
                        <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                            <div style="width:50%;margin-bottom:10px;" v-for="(rtItem, rtIndex) in item.startParameter.realData.realTagData" :key="rtIndex">
                                <div style="display:flex;">
                                    <el-button v-if="!rtItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(rtItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{rtItem.tag}}
                                        <span class="el-icon-error close-lube" @click="clearRtItemTag(rtItem)"></span>
                                    </span>
                                    <div v-show="item.startParameter.realData.realTagData.length-1!=rtIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">-</div>
                                </div>
                                
                                <!-- 运算符为 '-' 的侧滑选择Tag -->
                                <el-drawer
                                    title="选择Tag"
                                    :with-header="false"
                                    :visible.sync="rtItem.tagDrawer"
                                    direction="rtl"
                                    size="800px">
                                    <div class="drawer-title">选择Tag</div>
                                    <div class="drawer-content table-tree">
                                    <el-table :data="tagTableData" height="700">
                                        <el-table-column label="勾选" width="70">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="rtItem.tagRadio" @change="checkTagChange(scope.row)">
                                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                                            </el-radio-group>
                                        </template>
                                        </el-table-column>
                                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                        <el-table-column prop="pointName" label="点名"></el-table-column>
                                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                    </el-table>
                                    </div>
                                    <div class="drawer-bottom">
                                    <el-button size="small" type="primary" @click="rtItem.tagDrawer=false">取消</el-button>
                                    <el-button
                                        size="small"
                                        type="primary"
                                        @click="tagRtItemDrawerOK(rtItem)"
                                        class="drawer-bottom-ok">确 定</el-button>
                                    </div>
                                </el-drawer>
                            </div>
                        </div>
                        <div>
                        <el-input size="mini" v-model="item.startParameter.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                        </div>
                    </div>
                </div>
            
                <!-- 侧滑选择Tag -->
                <el-drawer
                    title="选择Tag"
                    :with-header="false"
                    :visible.sync="item.startParameter.tagDrawer"
                    direction="rtl"
                    size="800px">
                    <div class="drawer-title">选择Tag</div>
                    <div class="drawer-content table-tree">
                    <el-table :data="tagTableData" height="700">
                        <el-table-column label="勾选" width="70">
                        <template slot-scope="scope">
                            <el-radio-group v-model="item.startParameter.tagRadio" @change="checkTagChange(scope.row)">
                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                            </el-radio-group>
                        </template>
                        </el-table-column>
                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                        <el-table-column prop="pointName" label="点名"></el-table-column>
                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                    </el-table>
                    </div>
                    <div class="drawer-bottom">
                    <el-button size="small" type="primary" @click="item.startParameter.tagDrawer=false">取消</el-button>
                    <el-button
                        size="small"
                        type="primary"
                        @click="tagStartParameterOK(item)"
                        class="drawer-bottom-ok">确 定</el-button>
                    </div>
                </el-drawer>
            </div>
            <!-- 结束条件 -->
            <div class="animation-title animation-stop-title">结束条件</div>
            <div>
                <el-form-item label="数据类型">
                    <el-select size="mini" v-model="item.stopParameter.dataType" placeholder="请选择数据类型" @change="changeAnimationDataType">
                        <el-option
                            v-for="item in dataType"
                            :key="item"
                            :label="item"
                            :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <!-- Bool类型 -->
                <div v-show="item.stopParameter.dataType=='Bool'">
                    <el-form-item label="运算符">
                        <el-radio-group v-model="item.stopParameter.boolData.formulaType" @change="changePipeline">
                            <el-radio :label="'empty'">无</el-radio>
                            <el-radio :label="'&'">&</el-radio>
                            <el-radio :label="'||'">||</el-radio>
                            <el-radio :label="'!'">!</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!-- 运算符为 '无'和'!' -->
                    <div class="con-middle-tag-head" style="margin: 10px 0;" v-show="item.stopParameter.boolData.formulaType=='empty'||item.stopParameter.boolData.formulaType=='!'">
                        <div>
                            <el-button v-if="!item.stopParameter.boolData.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(item.stopParameter)">选择Tag</el-button>
                            <span v-else class="close-lube-btn">
                                {{item.stopParameter.boolData.tag}}
                                <span class="el-icon-error close-lube" @click="clearItemStopParameterTag(item)"></span>
                            </span>
                        </div>
                        <div>
                            <el-input size="mini" v-model="item.stopParameter.boolData.tagValue" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                        </div>
                    </div>
                    <!-- 运算符为 '&' -->
                    <div v-show="item.stopParameter.boolData.formulaType=='&'">
                        <div class="flex-change">
                            <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                            <div v-if="item.stopParameter.boolData.boolTagData">
                                <el-button type="text" @click="btAddTag(item.stopParameter.boolData.boolTagData)">新增</el-button>
                                <el-button :style="item.stopParameter.boolData.boolTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.stopParameter.boolData.boolTagData.length==2" @click="btDelTag(item.stopParameter.boolData.boolTagData)">删除</el-button>
                            </div>
                        </div>
                        <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                            <div style="width:50%;margin-bottom:10px;" v-for="(btItem, btIndex) in item.stopParameter.boolData.boolTagData" :key="btIndex">
                                <div style="display:flex;">
                                    <el-button v-if="!btItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(btItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{btItem.tag}}
                                        <span class="el-icon-error close-lube" @click="clearBtItemTag(btItem)"></span>
                                    </span>
                                    <div v-show="item.stopParameter.boolData.boolTagData.length-1!=btIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">&</div>
                                </div>
                                        
                                <!-- 运算符为 '&' 的侧滑选择Tag -->
                                <el-drawer
                                    title="选择Tag"
                                    :with-header="false"
                                    :visible.sync="btItem.tagDrawer"
                                    direction="rtl"
                                    size="800px">
                                    <div class="drawer-title">选择Tag</div>
                                    <div class="drawer-content table-tree">
                                    <el-table :data="tagTableData" height="700">
                                        <el-table-column label="勾选" width="70">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="btItem.tagRadio" @change="checkTagChange(scope.row)">
                                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                                            </el-radio-group>
                                        </template>
                                        </el-table-column>
                                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                        <el-table-column prop="pointName" label="点名"></el-table-column>
                                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                    </el-table>
                                    </div>
                                    <div class="drawer-bottom">
                                    <el-button size="small" type="primary" @click="btItem.tagDrawer=false">取消</el-button>
                                    <el-button
                                        size="small"
                                        type="primary"
                                        @click="tagBtItemDrawerOK(btItem)"
                                        class="drawer-bottom-ok">确 定</el-button>
                                    </div>
                                </el-drawer>
                            </div>
                        </div>
                        <div>
                            <el-input size="mini" v-model="item.stopParameter.boolData.tagValue" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                        </div>
                    </div>
                    <!-- 运算符为 '||' -->
                    <div v-show="item.stopParameter.boolData.formulaType=='||'">
                        <div style="display:flex;justify-content:space-between;align-items:center;">
                            <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                            <div v-if="item.stopParameter.boolData.boolTagData">
                                <el-button type="text" @click="btAddTag(item.stopParameter.boolData.boolTagData)">新增</el-button>
                                <el-button :style="item.stopParameter.boolData.boolTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.stopParameter.boolData.boolTagData.length==2" @click="btDelTag(item.stopParameter.boolData.boolTagData)">删除</el-button>
                            </div>
                        </div>
                        <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                            <div style="width:50%;margin-bottom:10px;" v-for="(btItem, btIndex) in item.stopParameter.boolData.boolTagData" :key="btIndex">
                                <div style="display:flex;">
                                    <el-button v-if="!btItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(btItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{btItem.tag}}
                                        <span class="el-icon-error close-lube" @click="clearBtItemTag(btItem)"></span>
                                    </span>
                                    <div v-show="item.stopParameter.boolData.boolTagData.length-1!=btIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">||</div>
                                </div>
                                
                                <!-- 运算符为 '||' 的侧滑选择Tag -->
                                <el-drawer
                                    title="选择Tag"
                                    :with-header="false"
                                    :visible.sync="btItem.tagDrawer"
                                    direction="rtl"
                                    size="800px">
                                    <div class="drawer-title">选择Tag</div>
                                    <div class="drawer-content table-tree">
                                    <el-table :data="tagTableData" height="700">
                                        <el-table-column label="勾选" width="70">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="btItem.tagRadio" @change="checkTagChange(scope.row)">
                                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                                            </el-radio-group>
                                        </template>
                                        </el-table-column>
                                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                        <el-table-column prop="pointName" label="点名"></el-table-column>
                                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                    </el-table>
                                    </div>
                                    <div class="drawer-bottom">
                                    <el-button size="small" type="primary" @click="btItem.tagDrawer=false">取消</el-button>
                                    <el-button
                                        size="small"
                                        type="primary"
                                        @click="tagBtItemDrawerOK(btItem)"
                                        class="drawer-bottom-ok">确 定</el-button>
                                    </div>
                                </el-drawer>
                            </div>
                        </div>
                        <div>
                            <el-input size="mini" v-model="item.stopParameter.boolData.tagValue" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入Tag值"></el-input>
                        </div>
                    </div>
                </div>
                <!-- Real类型 -->
                <div v-show="item.stopParameter.dataType=='Real'">
                    <el-form-item label="运算符">
                        <el-radio-group v-model="item.stopParameter.realData.formulaType" @change="changePipeline">
                            <el-radio :label="'empty'">无</el-radio>
                            <el-radio :label="'+'">+</el-radio>
                            <el-radio :label="'-'">-</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!-- 运算符为 '无' -->
                    <div class="con-middle-tag-head" style="margin: 10px 0;" v-show="item.stopParameter.realData.formulaType=='empty'">
                        <div>
                            <el-button v-if="!item.stopParameter.realData.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(item.stopParameter)">选择Tag</el-button>
                            <span v-else class="close-lube-btn">
                                {{item.stopParameter.realData.tag}}
                                <span class="el-icon-error close-lube" @click="clearItemTag(item)"></span>
                            </span>
                        </div>
                        <div>
                            <el-input size="mini" v-model="item.stopParameter.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                        </div>
                    </div>
                    <!-- 运算符为 '+' -->
                    <div v-show="item.stopParameter.realData.formulaType=='+'">
                        <div style="display:flex;justify-content:space-between;align-items:center;">
                            <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                            <div v-if="item.stopParameter.realData.realTagData">
                                <el-button type="text" @click="rtAddTag(item.stopParameter.realData.realTagData)">新增</el-button>
                                <el-button :style="item.stopParameter.realData.realTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.stopParameter.realData.realTagData.length==2" @click="rtDelTag(item.stopParameter.realData.realTagData)">删除</el-button>
                            </div>
                        </div>
                        <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                            <div style="width:50%;margin-bottom:10px;" v-for="(rtItem, rtIndex) in item.stopParameter.realData.realTagData" :key="rtIndex">
                                <div style="display:flex;">
                                    <el-button v-if="!rtItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(rtItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{rtItem.tag}}
                                        <span class="el-icon-error close-lube" @click="clearRtItemTag(rtItem)"></span>
                                    </span>
                                    <div v-show="item.stopParameter.realData.realTagData.length-1!=rtIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">+</div>
                                </div>
                                
                                <!-- 运算符为 '+' 的侧滑选择Tag -->
                                <el-drawer
                                    title="选择Tag"
                                    :with-header="false"
                                    :visible.sync="rtItem.tagDrawer"
                                    direction="rtl"
                                    size="800px">
                                    <div class="drawer-title">选择Tag</div>
                                    <div class="drawer-content table-tree">
                                    <el-table :data="tagTableData" height="700">
                                        <el-table-column label="勾选" width="70">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="rtItem.tagRadio" @change="checkTagChange(scope.row)">
                                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                                            </el-radio-group>
                                        </template>
                                        </el-table-column>
                                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                        <el-table-column prop="pointName" label="点名"></el-table-column>
                                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                    </el-table>
                                    </div>
                                    <div class="drawer-bottom">
                                    <el-button size="small" type="primary" @click="rtItem.tagDrawer=false">取消</el-button>
                                    <el-button
                                        size="small"
                                        type="primary"
                                        @click="tagRtItemDrawerOK(rtItem)"
                                        class="drawer-bottom-ok">确 定</el-button>
                                    </div>
                                </el-drawer>
                            </div>
                        </div>
                        <div>
                        <el-input size="mini" v-model="item.stopParameter.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                        </div>
                    </div>
                    <!-- 运算符为 '-' -->
                    <div v-show="item.stopParameter.realData.formulaType=='-'">
                        <div style="display:flex;justify-content:space-between;align-items:center;">
                            <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                            <div v-if="item.stopParameter.realData.realTagData">
                                <el-button type="text" @click="rtAddTag(item.stopParameter.realData.realTagData)">新增</el-button>
                                <el-button :style="item.stopParameter.realData.realTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.stopParameter.realData.realTagData.length==2" @click="rtDelTag(item.stopParameter.realData.realTagData)">删除</el-button>
                            </div>
                        </div>
                        <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                            <div style="width:50%;margin-bottom:10px;" v-for="(rtItem, rtIndex) in item.stopParameter.realData.realTagData" :key="rtIndex">
                                <div style="display:flex;">
                                    <el-button v-if="!rtItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(rtItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{rtItem.tag}}
                                        <span class="el-icon-error close-lube" @click="clearRtItemTag(rtItem)"></span>
                                    </span>
                                    <div v-show="item.stopParameter.realData.realTagData.length-1!=rtIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">-</div>
                                </div>
                                
                                <!-- 运算符为 '-' 的侧滑选择Tag -->
                                <el-drawer
                                    title="选择Tag"
                                    :with-header="false"
                                    :visible.sync="rtItem.tagDrawer"
                                    direction="rtl"
                                    size="800px">
                                    <div class="drawer-title">选择Tag</div>
                                    <div class="drawer-content table-tree">
                                    <el-table :data="tagTableData" height="700">
                                        <el-table-column label="勾选" width="70">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="rtItem.tagRadio" @change="checkTagChange(scope.row)">
                                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                                            </el-radio-group>
                                        </template>
                                        </el-table-column>
                                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                        <el-table-column prop="pointName" label="点名"></el-table-column>
                                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                    </el-table>
                                    </div>
                                    <div class="drawer-bottom">
                                    <el-button size="small" type="primary" @click="rtItem.tagDrawer=false">取消</el-button>
                                    <el-button
                                        size="small"
                                        type="primary"
                                        @click="tagRtItemDrawerOK(rtItem)"
                                        class="drawer-bottom-ok">确 定</el-button>
                                    </div>
                                </el-drawer>
                            </div>
                        </div>
                        <div>
                        <el-input size="mini" v-model="item.stopParameter.realData.unit" style="margin-left:5px;width:97%;" @change="changeItemTag" placeholder="请输入单位"></el-input>
                        </div>
                    </div>
                </div>
                <!-- 侧滑选择Tag -->
                <el-drawer
                    title="选择Tag"
                    :with-header="false"
                    :visible.sync="item.stopParameter.tagDrawer"
                    direction="rtl"
                    size="800px">
                    <div class="drawer-title">选择Tag</div>
                    <div class="drawer-content table-tree">
                    <el-table :data="tagTableData" height="700">
                        <el-table-column label="勾选" width="70">
                        <template slot-scope="scope">
                            <el-radio-group v-model="item.stopParameter.tagRadio" @change="checkTagChange(scope.row)">
                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                            </el-radio-group>
                        </template>
                        </el-table-column>
                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                        <el-table-column prop="pointName" label="点名"></el-table-column>
                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                    </el-table>
                    </div>
                    <div class="drawer-bottom">
                    <el-button size="small" type="primary" @click="item.stopParameter.tagDrawer=false">取消</el-button>
                    <el-button
                        size="small"
                        type="primary"
                        @click="tagStopParameterOK(item)"
                        class="drawer-bottom-ok">确 定</el-button>
                    </div>
                </el-drawer>
                <el-form-item label="结束状态">
                    <el-select size="mini" v-model="item.stopParameter.display" placeholder="请选择结束状态" @change="changeAnimationDataType">
                        <el-option
                            v-for="item in display"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
            </div>
        </div>
        <div v-if="drawerNode.withData==1">
            <el-form-item label="执行条件">
                <el-select size="mini" v-model="item.withoutData" placeholder="请选择执行条件" @change="changeAnimationDataType">
                    <el-option
                        v-for="item in withoutData"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"></el-option>
                </el-select>
            </el-form-item>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dataType: ['Bool','Real'],//文本 -> 数据类型
            withoutData: [//不关联的情况下执行其一
                { code: 'execute', name: '默认执行动画' },
                { code: 'noExecute', name: '默认不执行' },
            ],
            display: [//动画结束状态
                {code: 'hide', name: '隐藏' },
                {code: 'keep', name: '保持' },
            ],
            rateTypes: [//速率类型
                { code: 'manual', name: '手动' },
                { code: 'auto', name: '自动' },
            ],
            manualRates: [//动画速率
                { code: 0.5, name: '0.5倍速' },
                { code: 1, name: '1.0倍速' },
                { code: 1.5, name: '1.5倍速' },
                { code: 2, name: '2.0倍速' },
                { code: 3, name: '3.0倍速' },
                { code: 4, name: '4.0倍速' },
                { code: 5, name: '5.0倍速' },
            ],
            tagSelectObj: {},
        }
    },
    props: {
        selectCell:{
            type: String | Object
        },
        drawerNode: {
            type: Object
        },
        tagTableData: {
            type: Array
        },
        item: {
            type: Object
        }
    },
    methods: {
        changeAnimationDataType(val) {
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
        },
        changePipeline(val) {
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
        },
        // [动画]循环Tag清除
        clearItemTag(item) {
            item.tagName = null
            if(item.EmptyAnimation.boolData) {
                item.EmptyAnimation.boolData.tag = null
            }
            if(item.EmptyAnimation.realData) {
                item.EmptyAnimation.realData.tag = null                
            }
            if(item.startParameter.boolData) {
                item.startParameter.boolData.tag = null
            }
            if(item.startParameter.realData) {
                item.startParameter.realData.tag = null                
            }
            if(item.stopParameter.boolData) {
                item.stopParameter.boolData.tag = null
            }
            if(item.stopParameter.realData) {
                item.stopParameter.realData.tag = null                
            }
            this.$forceUpdate()
        },
        clearItemRelativeTag(item) {
            item.animationRate.relativeTag = null
            this.$forceUpdate()
        },
        // [动画]循环Tag选择
        selectItemTag(item) {
            item.tagDrawer = true
        },
        clearItemStartParameterTag(item) {
            if(item.startParameter.dataType=='Bool') {
                item.startParameter.boolData.tag = null
            }
            if(item.startParameter.dataType=='Real') {
                item.startParameter.realData.tag = null                
            }
        },

        clearItemStopParameterTag(item) {
            if(item.stopParameter.dataType=='Bool') {
                item.stopParameter.boolData.tag = null
            }
            if(item.stopParameter.dataType=='Real') {
                item.stopParameter.realData.tag = null                
            }
        },

        tagStartParameterOK(item) {
            console.log(item)
            if(item.startParameter.dataType=='Bool') {
                item.startParameter.boolData.tag = this.tagSelectObj.pointName
            }
            if(item.startParameter.dataType=='Real') {
                item.startParameter.realData.tag = this.tagSelectObj.pointName             
            }
            item.startParameter.tagDrawer = false
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
        },
        tagStopParameterOK(item) {
            console.log(item)
            if(item.stopParameter.dataType=='Bool') {
                item.stopParameter.boolData.tag = this.tagSelectObj.pointName
            }
            if(item.stopParameter.dataType=='Real') {
                item.stopParameter.realData.tag = this.tagSelectObj.pointName             
            }
            item.stopParameter.tagDrawer = false
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
        },
        tagItemDrawerOK(item) {
            item.animationRate.relativeTag = this.tagSelectObj.pointName
            item.tagDrawer = false
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
            console.log(this.selectCell)
        },
        // Bool运算符选择多个Tag
        btAddTag(arr) {
            arr.push({
                tag: '',
                tagDrawer: false,
                tagRadio: '',
            })
        },
        btDelTag(arr) {
            arr.pop()
        },
        clearBtItemTag(item) {
            item.tag = null
        },
        tagBtItemDrawerOK(item) {
            item.tag = this.tagSelectObj.pointName
            item.tagDrawer = false
        },
        // Real运算符选择多个Tag
        rtAddTag(arr) {
            arr.push({
                tag: '',
                tagDrawer: false,
                tagRadio: '',
            })
        },
        rtDelTag(arr) {
            arr.pop()
        },
        clearRtItemTag(item) {
            item.tag = null
        },
        tagRtItemDrawerOK(item) {
            item.tag = this.tagSelectObj.pointName
            item.tagDrawer = false
        },
        // 监听循环输入tag值
        changeItemTag(val) {
            console.log(val)
            this.selectCell.setData({
                graphical: {
                    customAnimations: this.drawerNode.customAnimations,
                }
            })
            console.log(this.selectCell)
        },
        // 勾选Tag
        checkTagChange(row) {
            this.tagSelectObj = row
        },
    }
}
</script>
<style lang="less" scoped>
    @import '../../../../../../css/iotConfigManage/rightDrawer.less';
</style>