/**
 * 导入数据-旋转
 */
 export const importRotate = (item,imgRotate) => {
    let pipe = item.imgRotate.startParameter
    let stopPipe = item.imgRotate.stopParameter

    if (pipe.dataType == 'Bool') {//start 旋转 Bool类型
        let boolData = {}
        let realData = {
            formulaType: 'empty',
            tag: '',
            unit: '',
            realTagData: [
                {
                    tag: '',
                    tagDrawer: false,
                    tagRadio: '',
                },
                {
                    tag: '',
                    tagDrawer: false,
                    tagRadio: '',
                }
            ]
        }
        let boolTagData = []
        if (pipe.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
            boolData = {
                formulaType: pipe.boolData.formulaType,
                tag: pipe.boolData.tag,
                tagValue: pipe.boolData.tagValue,
                boolTagData: [
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    }
                ]
            }
        }
        if (pipe.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
            let arr = pipe.boolData.tag.split('&')
            arr.forEach(arrv => {
                boolTagData.push({
                    tag: arrv,
                    tagDrawer: false,
                    tagRadio: '',
                })
            })
            boolData = {
                formulaType: pipe.boolData.formulaType,
                tag: '',
                tagValue: pipe.boolData.tagValue,
                boolTagData: boolTagData
            }
        }
        if (pipe.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
            let arr = pipe.boolData.tag.split('||')
            arr.forEach(arrv => {
                boolTagData.push({
                    tag: arrv,
                    tagDrawer: false,
                    tagRadio: '',
                })
            })
            boolData = {
                formulaType: pipe.boolData.formulaType,
                tag: '',
                tagValue: pipe.boolData.tagValue,
                boolTagData: boolTagData
            }
        }
        if (pipe.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
            boolData = {
                formulaType: pipe.boolData.formulaType,
                tag: pipe.boolData.tag,
                tagValue: pipe.boolData.tagValue,
                boolTagData: [
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    }
                ]
            }
        }

        imgRotate.startParameter = {
            dataType: pipe.dataType,
            boolData: boolData,
            realData: realData,
            tagDrawer: false,
            tagRadio: '',
        }
    }
    if (pipe.dataType == 'Real') {//start 旋转 Real类型
        let boolData = {
            formulaType: 'empty',
            tag: '',
            tagValue: '',
            boolTagData: [
                {
                    tag: '',
                    tagDrawer: false,
                    tagRadio: '',
                },
                {
                    tag: '',
                    tagDrawer: false,
                    tagRadio: '',
                }
            ]
        }
        let realData = {}
        let realTagData = []
        if (pipe.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
            realData = {
                formulaType: pipe.realData.formulaType,
                tag: pipe.realData.tag,
                unit: pipe.realData.unit,
                realTagData: [
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    }
                ]
            }
        }
        if (pipe.realData.formulaType == '+') {//Real类型 运算符为 '+'
            let arr = pipe.realData.tag.split('+')
            arr.forEach(arrv => {
                realTagData.push({
                    tag: arrv,
                    tagDrawer: false,
                    tagRadio: '',
                })
            })
            realData = {
                formulaType: pipe.realData.formulaType,
                tag: '',
                unit: pipe.realData.unit,
                realTagData: realTagData
            }
        }
        if (pipe.realData.formulaType == '-') {//Real类型 运算符为 '-'
            let arr = pipe.realData.tag.split('-')
            arr.forEach(arrv => {
                realTagData.push({
                    tag: arrv,
                    tagDrawer: false,
                    tagRadio: '',
                })
            })
            realData = {
                formulaType: pipe.realData.formulaType,
                tag: '',
                unit: pipe.realData.unit,
                realTagData: realTagData
            }
        }

        imgRotate.startParameter = {
            dataType: pipe.dataType,
            boolData: boolData,
            realData: realData,
            tagDrawer: false,
            tagRadio: '',
        }
    }

    if (stopPipe.dataType == 'Bool') {//stop 旋转 Bool类型
        let boolData = {}
        let realData = {
            formulaType: 'empty',
            tag: '',
            unit: '',
            realTagData: [
                {
                    tag: '',
                    tagDrawer: false,
                    tagRadio: '',
                },
                {
                    tag: '',
                    tagDrawer: false,
                    tagRadio: '',
                }
            ]
        }
        let boolTagData = []
        if (stopPipe.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
            boolData = {
                formulaType: stopPipe.boolData.formulaType,
                tag: stopPipe.boolData.tag,
                tagValue: stopPipe.boolData.tagValue,
                boolTagData: [
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    }
                ]
            }
        }
        if (stopPipe.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
            let arr = stopPipe.boolData.tag.split('&')
            arr.forEach(arrv => {
                boolTagData.push({
                    tag: arrv,
                    tagDrawer: false,
                    tagRadio: '',
                })
            })
            boolData = {
                formulaType: stopPipe.boolData.formulaType,
                tag: '',
                tagValue: stopPipe.boolData.tagValue,
                boolTagData: boolTagData
            }
        }
        if (stopPipe.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
            let arr = stopPipe.boolData.tag.split('||')
            arr.forEach(arrv => {
                boolTagData.push({
                    tag: arrv,
                    tagDrawer: false,
                    tagRadio: '',
                })
            })
            boolData = {
                formulaType: stopPipe.boolData.formulaType,
                tag: '',
                tagValue: stopPipe.boolData.tagValue,
                boolTagData: boolTagData
            }
        }
        if (stopPipe.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
            boolData = {
                formulaType: stopPipe.boolData.formulaType,
                tag: stopPipe.boolData.tag,
                tagValue: stopPipe.boolData.tagValue,
                boolTagData: [
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    }
                ]
            }
        }

        imgRotate.stopParameter = {
            dataType: stopPipe.dataType,
            boolData: boolData,
            realData: realData,
            display: stopPipe.display,
            tagDrawer: false,
            tagRadio: '',
        }
    }
    if (stopPipe.dataType == 'Real') {//stop 旋转 Real类型
        let boolData = {
            formulaType: 'empty',
            tag: '',
            tagValue: '',
            boolTagData: [
                {
                    tag: '',
                    tagDrawer: false,
                    tagRadio: '',
                },
                {
                    tag: '',
                    tagDrawer: false,
                    tagRadio: '',
                }
            ]
        }
        let realData = {}
        let realTagData = []
        if (stopPipe.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
            realData = {
                formulaType: stopPipe.realData.formulaType,
                tag: stopPipe.realData.tag,
                unit: stopPipe.realData.unit,
                realTagData: [
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    }
                ]
            }
        }
        if (stopPipe.realData.formulaType == '+') {//Real类型 运算符为 '+'
            let arr = stopPipe.realData.tag.split('+')
            arr.forEach(arrv => {
                realTagData.push({
                    tag: arrv,
                    tagDrawer: false,
                    tagRadio: '',
                })
            })
            realData = {
                formulaType: stopPipe.realData.formulaType,
                tag: '',
                unit: stopPipe.realData.unit,
                realTagData: realTagData
            }
        }
        if (stopPipe.realData.formulaType == '-') {//Real类型 运算符为 '-'
            let arr = stopPipe.realData.tag.split('-')
            arr.forEach(arrv => {
                realTagData.push({
                    tag: arrv,
                    tagDrawer: false,
                    tagRadio: '',
                })
            })
            realData = {
                formulaType: stopPipe.realData.formulaType,
                tag: '',
                unit: stopPipe.realData.unit,
                realTagData: realTagData
            }
        }

        imgRotate.stopParameter = {
            dataType: stopPipe.dataType,
            boolData: boolData,
            realData: realData,
            display: stopPipe.display,
            tagDrawer: false,
            tagRadio: '',
        }
    }
    return imgRotate
}